import { getColor, formatDateString } from '@/plugins/utils/utils';
export function setDayHRChartOption (hr_chart, hr_data) {
  if (!hr_chart || !hr_data) {
    return;
  }

  const data = [];
  const dataMask = [];
  let minOnLeft = false; // 判断最小值点的位置，用以防止标记画出边界
  let [minCoord, minValue] = [0, 0]; // 最小值点的 X 坐标

  if (hr_data && hr_data.segments) {
    const offset = hr_data.start;
    const end = hr_data.end;
    let lastend = 0;
    if (hr_data.segments[0].time > 0) {
      data.push([offset, null]);
    }

    for (let i = 0; i < hr_data.segments.length; i++) {
      data.push([offset + hr_data.segments[i].time, hr_data.segments[i].value <= 0 ? null : hr_data.segments[i].value]);
      lastend = offset + hr_data.segments[i].time;
    }
    if (lastend < end) {
      data.push([end, null]);
    }

    for (let i = 0; i < hr_data.nointention?.length; i++) {
      const seg = hr_data.nointention[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '活动', xAxis: hr_data.start + seg.s }, { xAxis: hr_data.start + seg.e }]); }
    }
    for (let i = 0; i < hr_data.leavebed?.length; i++) {
      const seg = hr_data.leavebed[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '离床', xAxis: hr_data.start + seg.s }, { xAxis: hr_data.start + seg.e }]); }
    }
    // 在非离床、非无意图范围内，寻找最小值点的位置
    let minIdx = 0;
    const sorted = data.toSorted((a, b) => a[1] - b[1]);
    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i][0] && sorted[i][1]) {
        let valid = true;
        for (let j = 0; j < dataMask.length; j++) {
          if (sorted[i][0] >= dataMask[j][0].xAxis && sorted[i][0] <= dataMask[j][1].xAxis) {
            valid = false;
            break;
          }
        }
        if (valid) {
          minCoord = sorted[i][0];
          minValue = sorted[i][1];
          minIdx = i;
          break;
        }
      }
    }
    if (minIdx > hr_data.segments.length / 2) {
      minOnLeft = false;
    } else {
      minOnLeft = true;
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: '整晚睡眠心率',
      padding: [0, 0, 0, 32],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      left: '2%',
      right: '7%',
      top: '16%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        lineStyle: { type: 'dashed' }
      }
    },
    yAxis: {
      type: 'value',
      interval: 10,
      min: function (value) { return Math.floor(value.min / 10) * 10 },
      max: function (value) { return Math.ceil(value.max / 10) * 10 }
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return params[0].marker +
                    formatDateString(params[0].value[0] * 1000, 'HHmm') +
                    '  ' + (params[0].value[1] ? Math.round(params[0].value[1]) + '次/分' : '无');
      }
    },
    series: [
      {
        type: 'line',
        symbol: 'none',
        data,
        itemStyle: { color: '#e7a370' },
        // smooth: true,
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            position: 'insideStartTop',
            formatter: function () {
              // return '平均' + Math.round(params.value);
              return '平均' + Math.round(hr_data.avg);
            }
          },
          data: [[{
            name: '平均',
            coord: [hr_data.start + (hr_data.intention || 0), hr_data.avg]
          }, {
            coord: [hr_data.end - (hr_data.leave ? (hr_data.end - hr_data.start - hr_data.leave) : 0), hr_data.avg]
          }]],
          lineStyle: { opacity: 0.8 }
        },
        markPoint: {
          silent: true,
          symbol: 'circle',
          // data: [ { type: 'min', name: 'Min', symbolSize: 10 } ],
          data: [{ name: 'Min', coord: [minCoord, minValue], symbolSize: 10 }],
          label: {
            position: minOnLeft ? 'right' : 'left',
            formatter: function (params) {
              return '最低' + Math.round(params.data.coord[1]);
            }
          },
          itemStyle: { opacity: 0.8 }
        },
        markArea: {
          silent: false,
          label: {
            show: false
          },
          itemStyle: {
            color: '#CCC',
            opacity: 0.4
          },
          emphasis: {
            label: {
              show: true,
              position: 'right',
              color: getColor('text')
            }
          },
          data: dataMask
        },
        encode: {
          x: 0,
          y: 1,
          tooltip: [0, 1]
        }
      }
    ]
  };

  hr_chart.setOption(option);
}
