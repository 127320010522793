

import axios from 'axios';
import {getCurrentInstance} from 'vue';
import {ElMessageBox} from 'element-plus';
import {store} from './store'; // 确保路径正确

const endpoints_debug = {
    '计算服务器1': 'https://data.wuji.com/b/',
    '计算服务器2': 'https://data.wuji.com/c/',
    '数据服务器': 'https://data.wuji.com/a/',
};
const endpoints_production = {
    '计算服务器1': 'https://data.wuji.com/b/',
    '计算服务器2': 'https://data.wuji.com/c/',
    '数据服务器': 'https://data.wuji.com/a/',
}

function get_base_url() {
    if (process.env.NODE_ENV === 'development') {
        return endpoints_debug['开发环境'];
    } else {
        return endpoints_production['数据服务器'];
    }
}

export const axiosInstance = axios.create({
    baseURL: get_base_url(), // 动态设置基础 URL
});

export function get_endpoints() {
    if (process.env.NODE_ENV === 'development') {
        return endpoints_debug;
    } else {
        return endpoints_production;
    }
}

export function get_endpoint(serverType) {
    if (process.env.NODE_ENV === 'development') {
        return endpoints_debug[serverType];
    } else {
        return endpoints_production[serverType];
    }
}


export async function request_data(url, serverType = "数据服务器") {
    try {
        const axiosInstance = createAxiosInstance(serverType);
        const response = await axiosInstance.get(url); // 使用新的 axios 实例请求数据
        if (response && response.status === 200 && response.data) {
            return response.data.data; // 假设响应的数据结构是 { data: { data: ... } }
        }
    } catch (error) {
        console.error("请求数据时发生错误:", error);
    }
    return null; // 如果请求失败或数据不满足条件，返回null
}

export function createAxiosInstance(serverType) {
    const endpoints = get_endpoints();
    let baseURL = endpoints['数据服务器']; // 默认使用实验服务器
    if (serverType && endpoints[serverType]) {
        baseURL = endpoints[serverType];
    }
    const token = localStorage.getItem('authToken');
    let a = axios.create({
        baseURL: baseURL,
        // withCredentials: true
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        },
    });
    // // 添加请求拦截器
    // a.interceptors.request.use(config => {
    //     // 在这里获取token并添加到请求头中
    //     const token = localStorage.getItem('authToken');
    //     if (token) {
    //         config.headers.Authorization = `${token}`;
    //     }
    //     return config;
    // });
    a.interceptors.response.use(response => response, error => {
        if (error.response && error.response.status === 401) {
            store.commit('setUser', null);
        } else if (!error.response) {
            store.commit('setNetError', '网络或系统故障，请稍后重连');
        } else {
            store.commit('setNetError', '服务器错误，类型:' + error.response.status);
        }
        return Promise.reject(error);
    });

    return a;
}

export async function do_task(taskurl, args, serverType = "数据服务器") {
    const params = Object.keys(args)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(args[key])}`)
        .join('&');
    if (!taskurl.endsWith("/")) taskurl = taskurl + "/";
    const response = await request_data(taskurl + "?" + params, serverType);

    ElMessageBox.alert(response.message);
    return response.result;
}

export async function wdb_get(db, item, tag, time) {
    //console.log("wdb_get",db,item,tag,time)
    let url = "";
    if (tag) url = '/wdb/' + db + '/' + item + '/' + tag + '/';
    else if (item) url = '/wdb/' + db + '/' + item + '/';
    else if (db) url = '/wdb/' + db + '/';
    else url = '/wdb/';
    if (time)
        url += '?time=' + time;
    return request_data(url);
}

export async function wdb_get_new(serverType, db, item, tag, time) {
    //console.log("wdb_get",db,item,tag,time)
    let url = "";
    if (tag) url = '/wdb/' + db + '/' + item + '/' + tag + '/';
    else if (item) url = '/wdb/' + db + '/' + item + '/';
    else if (db) url = '/wdb/' + db + '/';
    else url = '/wdb/';
    if (time)
        url += '?time=' + time;
    return request_data(url, serverType);
}

export async function wdb_get_history(db, item, history = 0) {
    let url = "";
    if (item) url = '/wdb/' + db + '/' + item + '/';
    else if (db) url = '/wdb/' + db + '/';
    else url = '/wdb/';
    if (history) url += '?history=' + history;
    return request_data(url);
}

export async function wdb_get_history_new(serverType, db, item, history = 0) {
    let url = "";
    if (item) url = '/wdb/' + db + '/' + item + '/';
    else if (db) url = '/wdb/' + db + '/';
    else url = '/wdb/';
    if (history) url += '?history=' + history;
    return request_data(url, serverType);
}

export function useAxios() {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error('useAxios must be called within a component setup');
    }
    // 直接返回axiosInstance，而不是通过globalProperties
    return createAxiosInstance("数据服务器");
}
