<template>
  <div class="p-4">
    <div class="mb-6">
      <h3 class="text-lg font-medium text-gray-900 mb-4">枕头控制面板</h3>
      <div class="bg-blue-50 rounded-lg p-4 mb-4">
        <div class="flex items-center text-blue-700">
          <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span>设备号：{{ device }}</span>
        </div>
      </div>
    </div>

    <!-- 控制面板 -->
    <div class="bg-white rounded-lg shadow p-6">
      <!-- 体重输入 -->
      <div class="mb-6">
        <label class="block text-sm font-medium text-gray-700 mb-2">体重设置 (kg)</label>
        <div class="flex items-center gap-4">
          <el-input-number
            v-model="weight"
            :min="30"
            :max="150"
            :step="0.1"
            controls-position="right"
            class="w-40"
            @change="handleWeightChange"
          />
          <el-button
            type="primary"
            size="default"
            :loading="isSettingWeight"
            @click="handleSetWeight"
          >
            更新设置
          </el-button>
        </div>
      </div>

      <!-- 控制按钮组 -->
      <!-- 控制按钮组 -->
      <div class="flex justify-center gap-4">
        <el-button
          v-for="(text, code) in pillow_control_code"
          :key="code"
          :type="getButtonColor(code)"
          :loading="isSettingControlCode"
          @click="handleControl(Number(code))"
        >
          {{ text }}
        </el-button>
      </div>
    </div>

    <!-- 状态显示 -->
    <div class="mt-6 bg-white rounded-lg shadow p-4">
      <h4 class="text-base font-medium text-gray-900 mb-4 flex justify-between items-center">
        <span>运行状态</span>
        <span class="text-sm text-gray-500">
          上次更新: {{ lastUpdateTime || '-- --' }}
        </span>              
      </h4>

      <!-- 添加更新时间显示 -->

      <div class="grid grid-cols-3 gap-4">
        <div class="bg-gray-50 rounded p-3">
          <div class="text-sm text-gray-500">当前体重</div>
          <div class="text-lg font-medium">{{ currentWeight }} kg</div>
        </div>
        <div class="bg-gray-50 rounded p-3">
          <div class="text-sm text-gray-500">枕头状态</div>
          <div class="text-lg font-medium">
            {{ currentPillowStatus.mp_status_text_s }}
          </div>
        </div>
      </div>
    </div>

    <!-- 添加 IMU 数据图表 -->
    <div class="mt-4 p-4 bg-white rounded-lg shadow">
      <h3 class="text-lg font-medium mb-4">IMU 数据监控</h3>
      <div class="flex gap-4 mb-4">
        <div class="flex items-center">
          <span class="text-gray-600 mr-2">X轴角度:</span>
          <span class="font-medium">{{ currentIMUData?.rot_deg_x?.toFixed(2) ?? '-' }}°</span>
        </div>
        <div class="flex items-center">
          <span class="text-gray-600 mr-2">Y轴角度:</span>
          <span class="font-medium">{{ currentIMUData?.rot_deg_y?.toFixed(2) ?? '-' }}°</span>
        </div>
      </div>
      <div ref="chartRef" style="width: 100%; height: 300px"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { ElMessage, ElButton, ElInputNumber } from 'element-plus'
import { createAxiosInstance } from '@/plugins/axios.js'
import dayjs from 'dayjs'
import * as echarts from 'echarts'
import { eventBus } from '@/plugins/websocket'

const props = defineProps({
  device: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['pillow-control'])

// 状态变量
const weight = ref(70)
const currentPillowStatus = ref({})
const currentWeight = ref(0)
const currentIMUData = ref(null)
const isSettingWeight = ref(false)
const isSettingControlCode = ref(false)

// 方法
const handleWeightChange = (value) => {
  weight.value = value
}

const handleSetWeight = async () => {
  try {
    isSettingWeight.value = true
    const axiosInstance = createAxiosInstance('数据服务器')
    
    await axiosInstance.post(`/pillows/${props.device}/cloud_control/set_parameter`, {
      weight: weight.value
    })
    
    currentWeight.value = weight.value
    ElMessage.success('体重设置成功')
    
    emit('pillow-control', {
      type: 'weight',
      weight: weight.value
    })
  } catch (error) {
    console.error('设置失败:', error)
    ElMessage.error('设置失败，请重试')
  } finally {
    isSettingWeight.value = false
  }
}

const pillow_control_code = {
    '-1': '暂停',
    '1': '干预',
    '2': '复位',
}

const handleControl = async (code) => {
  try {
    isSettingControlCode.value = true
    const axiosInstance = createAxiosInstance('计算服务器1')
    
    await axiosInstance.post(`/pillows/${props.device}/cloud_control/pillow_move`, {
        control_code: code
    })
    
    // ElMessage.success('操作成功')
    
    emit('pillow-control', {
      type: 'control',
      code: code
    })
  } catch (error) {
    console.error('操作失败:', error)
    ElMessage.error('操作失败，请重试')
  } finally {
    isSettingControlCode.value = false
  }
}

// 添加状态更新相关变量
const lastUpdateTime = ref('')
let statusTimer = null

// 修改状态获取方法
const fetchStatus = async (isInitial = false) => {
  try {
    const axiosInstance = createAxiosInstance('计算服务器1')
    const response = await axiosInstance.get(`/pillows/${props.device}/cloud_control/status`)
    
    if (response.data) {
      currentWeight.value = response.data.weight || 70
      // 如果是初始化调用，同时更新设置的体重值
      if (isInitial) {
        weight.value = response.data.weight || 70
      }
      currentPillowStatus.value = response.data.pillow_status || {}
      currentIMUData.value = response.data.imu_head || {}
      // 更新时间
      lastUpdateTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')

      // 更新IMU数据
      updateChart(currentIMUData.value)
    }
  } catch (error) {
    console.error('获取状态失败:', error)
    // 只在初始化时显示错误消息
    if (isInitial) {
      ElMessage.error('获取设备状态失败')
    }
  }
}

// 修改初始化方法
const initializeData = () => {
  return fetchStatus(true)
}

// 启动定时器
const startStatusTimer = () => {
  // 清除可能存在的旧定时器
  if (statusTimer) {
    clearInterval(statusTimer)
  }
  // 设置新的定时器，每秒获取一次状态
  statusTimer = setInterval(fetchStatus, 1000)
}

// 组件挂载时
onMounted(() => {
  initializeData()
  startStatusTimer()
  initChart()
  eventBus.on('imu_update', handleImuUpdate)
  
  // 处理窗口大小变化
  window.addEventListener('resize', () => {
    chart?.resize()
  })
})

// 组件卸载时清理定时器
onUnmounted(() => {
  if (statusTimer) {
    clearInterval(statusTimer)
    statusTimer = null
  }
  eventBus.off('imu_update', handleImuUpdate)
  chart?.dispose()
})

// 添加按钮类型映射方法
const getButtonColor = (code) => {
  switch (code) {
    case '1':
      return 'success'
    case '2':
      return 'warning'
    case '-1':
      return 'danger'
    default:
      return 'default'
  }
}

const chartRef = ref(null)
let chart = null
const maxDataPoints = 180 // 最多显示180秒的数据
const xData = ref([])
const yDataX = ref([])
const yDataY = ref([])
const yAxisRange = ref({
  min: -180,
  max: 180
})

// 修改更新图表数据的方法
const updateChart = (imuData) => {
  currentIMUData.value = imuData
  const currentTime = Math.floor(Date.now() / 1000)
  const startTime = currentTime - maxDataPoints
  
  // 如果是第一个数据点，初始化时间序列
  if (xData.value.length === 0) {
    for (let i = 0; i < maxDataPoints; i++) {
      xData.value.push(startTime + i)
    }
  } else {
    // 更新时间序列
    xData.value = xData.value.slice(1)
    xData.value.push(currentTime)
  }
  
  // 更新角度数据
  yDataX.value.push(imuData.rot_deg_x)
  yDataY.value.push(imuData.rot_deg_y)
  
  if (yDataX.value.length > maxDataPoints) {
    yDataX.value.shift()
    yDataY.value.shift()
  }
  
  // 计算Y轴范围
  const allValues = [...yDataX.value, ...yDataY.value]
  if (allValues.length > 0) {
    const minValue = Math.min(...allValues)
    const maxValue = Math.max(...allValues)
    const padding = (maxValue - minValue) * 0.1 // 添加10%的padding
    
    yAxisRange.value = {
      min: Math.floor(minValue - padding),
      max: Math.ceil(maxValue + padding)
    }
  }
  
  // 更新图表
  if (chart) {
    chart.setOption({
      xAxis: {
        data: xData.value.map(time => time % 100)
      },
      yAxis: {
        min: yAxisRange.value.min,
        max: yAxisRange.value.max
      },
      series: [
        {
          data: yDataX.value
        },
        {
          data: yDataY.value
        }
      ]
    })
  }
}

// 修改初始化图表的方法
const initChart = () => {
  if (!chartRef.value) return
  
  chart = echarts.init(chartRef.value)
  const option = {
    title: {
      text: 'IMU 角度数据',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        return params.map(param => {
          return `${param.seriesName}: ${param.value?.toFixed(2) ?? '-'}°`
        }).join('<br/>')
      }
    },
    legend: {
      data: ['X轴角度', 'Y轴角度'],
      top: 30
    },
    xAxis: {
      type: 'category',
      interval: 10,
      show: false,
      data: xData.value,
      name: '时间 (秒)',
      axisLabel: {
        formatter: (value) => value + 's'
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      name: '角度 (°)',
      min: yAxisRange.value.min,
      max: yAxisRange.value.max,
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    series: [
      {
        name: 'X轴角度',
        type: 'line',
        data: yDataX.value,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: '#5470c6'
        }
      },
      {
        name: 'Y轴角度',
        type: 'line',
        data: yDataY.value,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: '#91cc75'
        }
      }
    ]
  }
  
  chart.setOption(option)
}

// 监听 IMU 数据更新
const handleImuUpdate = (data) => {
  if (data.status?.imu_head) {
    updateChart(data.status.imu_head)
  }
}
</script>

<style scoped>
.el-input-number {
  width: 160px !important;
}

/* 状态卡片样式 */
.bg-gray-50 {
  transition: all 0.3s ease;
}

.bg-gray-50:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 添加更新时间样式 */
.text-right {
  text-align: right;
}
</style> 