import { getColor } from '@/plugins/utils/utils';

import { locales } from '@/plugins/utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];
export function setEnvChartOption (reports, env_chart) {
  if (!env_chart) {
    return;
  }

  const dataEnv = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        let t = (reports[key].avg_temp);
        let h = (reports[key].avg_humi);
        let i = (reports[key].high_illu * 100);
        if (!t) t = null;
        if (!h) h = null;
        if (typeof (i) === 'undefined') i = null;
        dataEnv.push([abbrKey, t, h, i]);
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [t_avg, h_avg, i_avg, count] = [0, 0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        let t = chart_dates[key] >= 0 ? (reports[chart_dates[key]].avg_temp) : null;
        let h = chart_dates[key] >= 0 ? (reports[chart_dates[key]].avg_humi) : null;
        let i = chart_dates[key] >= 0 ? (reports[chart_dates[key]].high_illu * 100) : null;
        if (!t) t = null;
        if (!h) h = null;
        if (typeof (i) === 'undefined') i = null;
        if (t != null && h != null && i != null && valid) {
          t_avg += t;
          h_avg += h;
          i_avg += i;
          count++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            t_avg = count ? (t_avg / count) : null;
            h_avg = count ? (h_avg / count) : null;
            i_avg = count ? (i_avg / count) : null;

            key = month + '-' + day + L.to + weekend;
            dataEnv.push([key, t_avg, h_avg, i_avg]);
            dataXAxis.push(key);
            [t_avg, h_avg, i_avg, count] = [0, 0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataXAxis.reverse();
  dataEnv.reverse();

  const colors = ['#e7a370', '#3C8DD0', '#3CB043'];
  const option = {
    backgroundColor: 'transparent',
    animationDuration: 500,
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return params[0].value[0] + '\n' +
                    params[0].marker + params[0].seriesName + '  ' + (params[0].value[1] !== null ? Math.round(params[0].value[1] * 10) / 10 + '°C' : L.nodata_abbr) + '\n' +
                    params[1].marker + params[1].seriesName + '  ' + (params[1].value[2] !== null ? Math.round(params[1].value[2]) + '%' : L.nodata_abbr) + '\n' +
                    params[2].marker + params[2].seriesName + '  ' + (params[2].value[3] !== null ? Math.round(params[2].value[3]) + '%' : L.nodata_abbr);
      }
    },
    grid: {
      top: '20%',
      left: '3%',
      right: '3%',
      bottom: '2%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth !== endMonth || startDay === 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: [{
      name: L.temperature_abbr,
      type: 'value',
      position: 'right',
      alignTicks: true,
      nameGap: 8,
      axisTick: { show: true },
      axisLine: {
        show: true,
        lineStyle: { color: colors[0] }
      },
      axisLabel: {
        // formatter: '{value}°C'
        formatter: (value) => {
          const c = Math.round(value * 10) / 10;
          if (value === c) { return c + '°C'; } else { return null; }
        }
      },
      min: function (value) {
        return Math.floor(value.min / 2) * 2
      },
      max: function (value) {
        return Math.ceil(value.max / 2) * 2
      },
      minInterval: 2
    }, {
      name: L.humidity_abbr,
      type: 'value',
      position: 'right',
      alignTicks: true,
      offset: 40,
      nameGap: 8,
      axisTick: { show: true },
      axisLine: {
        show: true,
        lineStyle: { color: colors[1] }
      },
      axisLabel: {
        // formatter: '{value}%'
        formatter: (value) => {
          const c = Math.round(value * 10) / 10;
          if (value === c) { return c + '%'; } else { return null; }
        }
      },
      min: function (value) {
        return Math.floor(value.min / 5) * 5
      },
      // max: function (value) { return Math.ceil(value.max/10)*10 },
      minInterval: 2
    }, {
      name: L.light_exposure_ratio,
      nameTextStyle: {
        padding: [0, 0, 0, 45]
      },
      type: 'value',
      position: 'left',
      alignTicks: true,
      nameGap: 8,
      // max: function (value) { return value.max < 5 ? 5 : Math.ceil(value.max + 5); },
      min: 0,
      axisTick: { show: true },
      axisLine: {
        show: true,
        lineStyle: { color: colors[2] }
      },
      axisLabel: {
        // formatter: '{value}%'
        formatter: (value) => {
          const c = Math.round(value * 10) / 10;
          if (Math.abs(value - c) < 0.01) { return c + '%'; } else { return null; }
        }
      }
    }],
    series: [{
      name: L.temperature,
      type: 'line',
      smooth: true,
      data: dataEnv,
      itemStyle: { color: colors[0] },
      yAxisIndex: 0,
      encode: {
        x: 0,
        y: 1
      },
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataEnv) },
      symbol: 'circle'
      // showAllSymbol: 'true',
    }, {
      name: L.humidity,
      type: 'line',
      smooth: true,
      data: dataEnv,
      itemStyle: { color: colors[1] },
      yAxisIndex: 1,
      encode: {
        x: 0,
        y: 2
      },
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataEnv) },
      symbol: 'circle'
      // showAllSymbol: 'true',
    }, {
      name: L.light_ratio,
      type: 'line',
      smooth: true,
      data: dataEnv,
      itemStyle: { color: colors[2] },
      yAxisIndex: 2,
      encode: {
        x: 0,
        y: 3
      },
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataEnv) },
      symbol: 'circle'
      // showAllSymbol: 'true',
    }]
  };
  env_chart.setOption(option);
}
