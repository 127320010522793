<template>
    <div class="flex min-h-screen bg-gray-100">
      <!-- 左侧导航栏 -->
      <nav :class="[
        'bg-white shadow-xl fixed h-full flex flex-col transition-all duration-300',
        isNavCollapsed ? 'w-20' : 'w-64'
      ]">
        <!-- Logo区域 -->
        <div class="flex items-center px-4 py-4"
             :class="[isNavCollapsed ? 'justify-center' : 'justify-between']">
          <div class="flex items-center">
            <img 
              :src="isNavCollapsed ? logo1 : logo"
              :class="[
                'transition-all duration-300',
                isNavCollapsed ? 'h-8 w-8' : 'h-8 w-auto'
              ]"
              alt="Logo"
            />
          </div>
          <!-- 收缩按钮在展开状态时才显示 -->
          <button 
            v-if="!isNavCollapsed"
            @click="toggleNav" 
            class="p-1 rounded-lg text-gray-400 hover:bg-gray-100"
          >
            <!-- 按钮内容 -->
          </button>
        </div>
        
        <!-- 导航菜单 -->
        <div class="flex-1 overflow-y-auto px-2 py-6">
          <div class="space-y-1">
            <router-link 
              v-for="item in menuItems" 
              :key="item.path"
              :to="item.path"
              class="flex items-center py-3 text-sm font-medium rounded-lg transition-colors duration-150"
              :class="[
                currentRoute === item.path 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                isNavCollapsed ? 'justify-center px-2' : 'px-4'
              ]"
            >
              <component 
                :is="item.icon" 
                class="w-6 h-6"
                :class="[
                  currentRoute === item.path ? 'text-blue-600' : 'text-gray-400'
                ]"
              />
              <span v-if="!isNavCollapsed" class="ml-3">{{ item.name }}</span>
            </router-link>
          </div>
        </div>
        
        <!-- 用户信息和退出登录 -->
        <div class="border-t border-gray-200 px-4 py-3">
          <div class="flex items-center justify-between">
            <div v-if="!isNavCollapsed" class="text-sm text-gray-600">
              {{  user.username || user.phoneNumber || '未知用户' }}
            </div>
            <button
              @click="logout"
              class="flex items-center text-sm text-gray-600 hover:text-gray-900 transition-colors duration-150"
              :class="{ 'mx-auto': isNavCollapsed }"
            >
              <ArrowLeftOnRectangleIcon class="h-4 w-4" :class="{ 'mr-1': !isNavCollapsed }" />
              <span v-if="!isNavCollapsed">退出</span>
            </button>
          </div>
        </div>

        <!-- 添加收起/展开按钮 -->
        <button 
          @click="toggleNav"
          class="absolute -right-3 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:bg-gray-50"
        >
          <svg 
            class="w-4 h-4 text-gray-600"
            :class="{ 'rotate-180': isNavCollapsed }"
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
      </nav>
  
      <!-- 主要内容区域 -->
      <div 
        class="flex-1 transition-all duration-300"
        :class="[isNavCollapsed ? 'ml-16' : 'ml-64']"
      >
        <div class="p-6">
          <router-view @view-report="collapseNav"></router-view>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js"; // 假设这是你的axios实例创建函数
// 在顶部导入图片
import logo from '@/assets/logo.png'
import logo1 from '@/assets/logo_1.png'
  // 修改图标导入方式
  import {
    ComputerDesktopIcon,
    DocumentTextIcon,
    UserGroupIcon,
    Cog8ToothIcon,
    ArrowLeftOnRectangleIcon
  } from '@heroicons/vue/24/outline'  // 使用 outline 风格的图标
  
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  
  const user = computed(() => store.state.user)
  const currentRoute = computed(() => route.path)
  
  const menuItems = [
    {
      name: '设备管理',
      path: '/devices',
      icon: ComputerDesktopIcon
    },
    {
      name: '报告管理',
      path: '/reports',
      icon: DocumentTextIcon
    },
    // {
    //   name: '用户管理',
    //   path: '/users',
    //   icon: UserGroupIcon
    // },
    // {
    //   name: '系统设置',
    //   path: '/settings',
    //   icon: Cog8ToothIcon  // 注意这里图标名称也略有变化
    // }
  ]
  
  const logout = async () => {
    try {
      const axiosInstance = createAxiosInstance("数据服务器");
      // 清除所有相关数据
      store.commit('clearUser');
      store.commit('clearDeviceData'); // 需要在 store 中定义这个 mutation
      store.commit('clearReportData'); // 需要在 store 中定义这个 mutation
      localStorage.removeItem('authToken');
      
      router.push('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const isNavCollapsed = ref(false)

  const toggleNav = () => {
    isNavCollapsed.value = !isNavCollapsed.value
  }

  const collapseNav = () => {
    isNavCollapsed.value = true;
  }
  </script>
  
  <style scoped>
  /* 添加滚动条样式 */
  .overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: #E5E7EB transparent;
  }
  
  .overflow-y-auto::-webkit-scrollbar {
    width: 4px;
  }
  
  .overflow-y-auto::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #E5E7EB;
    border-radius: 2px;
  }
  </style>