import { locales } from '@/plugins/utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';
import { getColor, getRankColor, seconds2HHmm } from '@/plugins/utils/utils.js';


const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

export function setTSTChartOption (reports, tst_chart) {
  if (!tst_chart) {
    return;
  }
  const dataTST = [];
  const dataTIB = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        var tst, tib, start, end;

        if (reports[key] && Object.keys(reports[key]).length > 0) {
          tst = Math.round(reports[key].tst);
          tib = Math.round(reports[key].tib);
          start = Math.round(reports[key].start);
          end = Math.round(reports[key].end);
        } else {
          tst = null;
          tib = null;
          start = null;
          end = null;
        }

        if (!tib || tib >= 24 * 60) { // 数据非法
          tib = (end - start) / 60;
        }
        if (!tst) tst = null;
        if (!tib) tib = null;
        const c = chart_dates[key] >= 0 ? getRankColor(reports[chart_dates[key]], 'tst', tst) : null;
        dataTST.push({ name: abbrKey, value: tst, itemStyle: { color: c } });
        dataTIB.push({ name: abbrKey, value: tib });
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [tst_avg, tib_avg, count] = [0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      let birthdate = null;
      for (const key in chart_dates) {
        const bd = chart_dates[key] >= 0 ? reports[chart_dates[key]].birthdate : null;
        if (bd) {
          const bdarr = bd.split('-');
          if (bdarr.length == 2) {
            const d = new Date();
            d.setFullYear(bdarr[0]);
            d.setMonth(bdarr[1] - 1);
            d.setDate(15);
            d.setHours(0, 0, 0, 0);
            birthdate = d;
          }
          break;
        }
      }

      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        const tst = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].tst) : null;
        let tib = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].tib) : null;
        const start = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].start) : null;
        const end = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].end) : null;
        if (!tib || tib >= 24 * 60) { // 数据非法
          tib = (end - start) / 60;
        }
        if (tst != null && tib != null && valid) {
          tst_avg += tst;
          tib_avg += tib;
          count++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            tst_avg = count ? Math.round(tst_avg / count) : null;
            tib_avg = count ? Math.round(tib_avg / count) : null;
            key = month + '-' + day + L.to + weekend;

            const age = birthdate ? Math.round(100 * (d - birthdate) / (86400 * 1000 * 365)) / 100 : 38;
            const c = getRankColor(null, 'tst', tst_avg, age);
            dataTST.push({ name: key, value: tst_avg, itemStyle: { color: c } });
            dataTIB.push({ name: key, value: tib_avg });
            dataXAxis.push(key);
            [tst_avg, tib_avg, count] = [0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataTST.reverse();
  dataTIB.reverse();
  dataXAxis.reverse();

  const option = {
    animationDuration: 500,
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      valueFormatter: (value) => {
        if (value) {
          return seconds2HHmm(value * 60, true);
        } else {
          return '-';
        }
      }
    },
    legend: {
      data: [{ name: L.total_sleep_time }, { name: L.time_in_bed }],
      orient: 'horizontal',
      bottom: 1,
      selectedMode: true,
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 20,
      itemGap: 6,
      textStyle: {
        fontSize: 12,
        padding: [0, 0, 0, -6]
      }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '3%',
      bottom: weekmode ? '18%' : '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth != endMonth || startDay == 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: {
      type: 'value',
      interval: 60,
      min: function (value) {
        return Math.floor(value.min / 120) * 120
      },
      axisLabel: {
        show: true,
        formatter: (value) => {
          return Math.round(value / 60) + L.time_hr;
        }
      }
    },
    series: [{
      name: L.time_in_bed,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTIB); },
      symbol: 'circle',
      itemStyle: { color: '#73c0de' },
      data: dataTIB
    }, {
      name: L.total_sleep_time,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTST); },
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      data: dataTST
    }]
  };
  tst_chart.setOption(option);
}

export function setSEChartOption (reports, se_chart) {
  if (!se_chart) {
    return;
  }

  const dataSE = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        const tst = Math.round(reports[key].tst);
        let tib = Math.round(reports[key].tib);
        if (tib == null || tib > 24 * 60) {
          tib = Math.round(reports[key].end - reports[key].start);
          tib = tib / 60;
        }
        const se = (tst && tib && tib < 24 * 60) ? Math.round(tst / tib * 100) : null;
        dataSE.push({ name: abbrKey, value: se, itemStyle: { color: '#73c0de' } });
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [se_avg, count] = [0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        const tst = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].tst) : null;
        let tib = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].tib) : null;
        if (tib == null || tib > 24 * 60) {
          tib = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].end - reports[chart_dates[key]].start) : null;
          tib = tib / 60;
        }
        const se = (tst && tib && tib < 24 * 60) ? Math.round(tst / tib * 100) : null;
        if (se != null && valid) {
          se_avg += se;
          count++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            se_avg = count ? Math.round(se_avg / count) : null;
            key = month + '-' + day + L.to + weekend;
            const c = getRankColor(null, 'se', se_avg);
            dataSE.push({ name: key, value: se_avg, itemStyle: { color: c } });
            dataXAxis.push(key);
            [se_avg, count] = [0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataSE.reverse();
  dataXAxis.reverse();

  const option = {
    animationDuration: 500,
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      valueFormatter: (value) => (value != null) ? value + '%' : '-'
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '3%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth != endMonth || startDay == 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: {
      type: 'value',
      min: function (value) {
        if (value.min >= 50) {
          return 50;
        } else {
          return Math.floor(value.min / 20) * 20;
        }
      },
      max: 100,
      minInterval: 10,
      axisLabel: {
        show: true,
        formatter: '{value}%'
      }
    },
    series: [{
      name: L.sleep_efficiency,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataSE); },
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      data: dataSE
    }]
  };
    // console.log(se_chart, option);
  se_chart.setOption(option);
}
