<template>
  <div class="mobile-login-container">
    <!-- 添加背景图容器 -->
    <div class="background-container">
      <img src="@/assets/index.png" alt="背景图" class="background-image">
      <!-- 可以添加一个渐变遮罩 -->
<!--      <div class="gradient-overlay"></div>-->
    </div>

    <div class="mobile-content">
      <div class="mobile-header">
        <h1 class="welcome-text">欢迎使用</h1>
        <h2 class="product-name">五季医学睡眠监测工作站</h2>
      </div>

      <div class="mobile-form">
        <h2 class="login-title">登录账号</h2>
        <form @submit.prevent="handleLogin">
          <div class="form-item">
            <input
              type="tel"
              v-model="loginForm.username"
              placeholder="请输入账号"
              class="input-field"
            >
          </div>
          <div class="form-item">
            <input
              type="password"
              v-model="loginForm.password"
              placeholder="请输入密码"
              class="input-field"
            >
          </div>
          <div class="form-options">
            <label class="remember-me">
              <input type="checkbox" v-model="rememberPassword">
              <span>记住密码</span>
            </label>
            <!-- <a href="#" class="forgot-password"  @click="password_btn">忘记密码？</a> -->
          </div>
          <span class="error-message" v-if="loginError">{{ loginError }}</span>

          <button type="submit" class="login-button">登录</button>
        </form>

        <!-- <div class="divider">
        </div> -->

        <!-- <div class="alternative-login">
          <button class="alt-login-btn" @click="login_btn">手机验证码登录</button>
          <button class="alt-login-btn" @click="register_btn">注册账号</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.mobile-login-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* 背景图相关样式 */
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.95) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* 内容区域样式 */
.mobile-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.mobile-header {
  padding: 40px 0;
}

.welcome-text {
  color: #30729F;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 20px;
}

.product-name {
  color: #30729F;
  font-size: 32px;
  font-weight: bold;
    margin-left: 20px;

}

.mobile-form {
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
}

.login-title {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
  font-weight:bold;
}

.form-item {
  margin-bottom: 16px;
}


.input-field {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.forgot-password {
  color: #5B7FF3;
  text-decoration: none;
}

.login-button {
  width: 100%;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.divider {
  position: relative;
  margin: 24px 0;
}


.alternative-login {
  display: flex;
  gap: 16px;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}


/* 输入框获得焦点时的样式 */
.input-field:focus {
  border-color: #5B7FF3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(91, 127, 243, 0.2);
}

/* 按钮悬停效果 */
.login-button:hover {
  background: #4B6FD3;
}

.alt-login-btn:hover {
  background: rgba(91, 127, 243, 0.1);
}

/* 复选框样式优化 */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: block;
}

</style>

<script setup>
import {defineProps, ref, watch, defineEmits, onMounted} from "vue";

import {useRouter} from 'vue-router';

import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js"; // 假设这是你的axios实例创建函数
import {store} from "@/plugins/store.js"; // 假设这是你的axios实例创建函数
import md5 from 'md5';

const router = useRouter();
const rememberPassword = ref('')
const loginForm = ref({
  username: '',
  password: '',
});
const register_btn = async () => {
      router.push('/register'); //

}
const login_btn = async () => {
      router.push('/login'); //

}
const password_btn = async () => {
  router.push('/retrieve'); //
}
const loginError = ref(false);
const loginSuccessMessage = ref('');
onMounted(() => {
  // 从 localStorage 获取保存的用户名和密码
  const savedUsername = localStorage.getItem('savedUsername');
  const savedPassword = localStorage.getItem('savedPassword');
  if (savedUsername && savedPassword) {
    loginForm.value.username = savedUsername;
    loginForm.value.password = savedPassword;
    rememberPassword.value = true;
  }
});

const handleLogin = async (event) => {
  event.preventDefault(); // 阻止表单默认提交行为
  loginError.value = false; // 重置登录错误状态
    const LoginForm = {
      username: loginForm.value.username,
      password: md5(loginForm.value.password)
    };
  try {
  const axios = createAxiosInstance("数据服务器");
  const response = await axios.post('/login/', LoginForm, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  console.log(response.data);
  if (response.status === 200) {
    const token = response.data.data.token;  // 提取令牌
    console.log(token);
    // 将 token 存入 localStorage
    localStorage.setItem('authToken', token);

    loginSuccessMessage.value = '登录成功！正在加载用户信息...';
    loginError.value = false;
    store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
    router.push('/devices'); //
  }
  // 如果选择了“记住密码”，则保存用户名和密码到 localStorage
  if (rememberPassword.value) {
    localStorage.setItem('savedUsername', loginForm.value.username);
    localStorage.setItem('savedPassword', loginForm.value.password);
  } else {
    // 没有选择“记住密码”，则清除存储的用户名和密码
    localStorage.removeItem('savedUsername');
    localStorage.removeItem('savedPassword');
  }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // 特定处理401错误
      loginError.value = "请输入正确的用户或密码，在非微信浏览器中使用";
    } else {
      // 处理其他错误
      loginError.value = "登录失败：" + error.message;
    }
    console.log("用户或密码错误");
  }
};
</script>
