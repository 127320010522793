<!-- 修改 template 部分的最外层结构 -->
<template>
  <!-- Loading 遮罩保持不变 -->
  <div v-if="isLoading" class="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
    <div class="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent"></div>
  </div>

  <!-- 报告页面容器 -->
  <div class="report-container">
    <!-- 第一页 -->
    <div class="report-page">
      <!-- 页眉 -->
      <header class="page-header">
        <div class="flex justify-between items-center px-4 pb-3">
          <h1 class="text-base font-medium text-gray-900">睡眠分析报告</h1>
          <span class="text-black/80 text-sm">{{ formatChineseDate(selectedDate) }}</span>
        </div>
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent"></div>
      </header>

      <!-- 第一页内容 -->
      <div class="page-content space-y-4">
        <!-- 基本信息 Card -->
        <div class="content-section">
          <!-- User Info Card -->
        <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
          <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
            <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
            基本信息
          </h2>
          
          <div class="grid grid-cols-4 lg:grid-cols-8 gap-1.5">
            <!-- 基础信息 -->
            <template v-for="(item, index) in baseInfoItems" :key="'base-' + index">
              <div class="bg-gray-50 rounded-lg p-1.5 hover:bg-gray-100/80 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <span class="text-xs text-gray-500">{{ item.label }}</span>
                  <span class="text-xs font-medium text-gray-900">{{ item.value }}</span>
                </div>
              </div>
            </template>

            <!-- 自定义字段 -->
            <template v-for="(field, key) in customFields" :key="'custom-' + key">
              <div class="bg-gray-50 rounded-lg p-1.5 hover:bg-gray-100/80 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <span class="text-xs text-gray-500">{{ key }}</span>
                  <span class="text-xs font-medium text-gray-900">{{ field.value }} {{ field.unit }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
  
        </div>

        <!-- 睡眠分析 Card -->
        <div class="content-section">
  <!-- Sleep Analysis -->
        <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
          <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
            <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
            睡眠分析
          </h2>
          
          <div class="space-y-1.5">
            <!-- 第一行：睡眠时间 -->
            <div class="bg-gray-50 rounded-lg p-2">
              <h3 class="text-xs font-medium text-gray-700 mb-1">睡眠时间</h3>
              <div class="grid grid-cols-4 gap-2">
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">关灯时间</span>
                  <span class="text-sm font-bold text-blue-600">{{ formatTime(data.sleep_intention_time) }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">开灯时间</span>
                  <span class="text-sm font-bold text-blue-600">{{ formatTime(data.intention_leave_time) }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">总睡眠时长</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[2].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠效率</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[3].value }}</span>
                </div>
              </div>
            </div>

            <!-- 第二行：睡眠质量 -->
            <div class="bg-gray-50 rounded-lg p-2">
              <h3 class="text-xs font-medium text-gray-700 mb-1">睡眠质量</h3>
              <div class="grid grid-cols-4 gap-2">
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">入睡时间</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[4].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠稳定</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[5].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">深度睡眠</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[6].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">快速眼动潜伏期</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[7].value }}</span>
                </div>
              </div>
            </div>

            <!-- 第三行：睡眠评分 -->
            <div class="bg-gray-50 rounded-lg p-2">
              <h3 class="text-xs font-medium text-gray-700 mb-1">睡眠评分</h3>
              <div class="grid grid-cols-4 gap-2">
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠中断</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[5].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠碎片化</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[7].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠规律性</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[8].value }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-xs text-gray-600">睡眠质量</span>
                  <span class="text-sm font-bold text-blue-600">{{ computedSleepAnalysis[9].value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          </div>

        <!-- 睡眠结构 Card -->
        <div class="content-section">
 <!-- Sleep Structure -->
 <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
  <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
    <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
    睡眠结构
  </h2>

  <div class="flex flex-col md:flex-row gap-2">
    <!-- 左侧睡眠阶段统计 -->
    <div class="w-full md:w-1/4">
      <div class="grid grid-cols-1 gap-1.5">
        <div v-for="(phase, index) in computedSleepPhases" 
             :key="index"
             class="bg-gray-50 rounded-lg p-2.5"
             :class="{
               'border-l-4 border-l-orange-400': index === 0,
               'border-l-4 border-l-blue-300': index === 1,
               'border-l-4 border-l-blue-500': index === 2,
               'border-l-4 border-l-blue-700': index === 3
             }"
        >
          <!-- 标题行 -->
          <div class="text-sm font-medium text-gray-700 mb-1">
            {{ phase.label }}
          </div>
          
          <!-- 数据行 -->
          <div class="flex items-center justify-between">
            <!-- 时长 -->
            <div class="flex items-baseline">
              <span class="text-base font-semibold text-gray-900">{{ phase.value }}</span>
            </div>
            <!-- 百分比 -->
            <span class="text-sm font-medium text-blue-600">{{ phase.percentage }}</span>
          </div>
        </div>
      </div>
    </div>

  <!-- 右侧图表区域 -->
  <div class="w-full md:w-3/4 flex flex-col gap-2">
    <!-- 睡眠结构图表 -->
    <div class="flex-1 bg-white rounded-lg border border-gray-100">
      <div class="h-[140px] relative">
        <div :id="chartId" class="w-full h-full"></div>
        <div v-if="!data" class="absolute inset-0 flex items-center justify-center bg-gray-50/80">
          <div class="animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
        </div>
      </div>
    </div>

    <!-- 其他图�� -->
    <div class="flex-1 bg-white rounded-lg border border-gray-100">
      <div class="h-[140px] relative">
        <div :id="ArtChartId" class="w-full h-full"></div>
        <div v-if="!data" class="absolute inset-0 flex items-center justify-center bg-gray-50/80">
          <div class="animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>        </div>
      </div>

      <!-- 页脚 -->
      <footer class="page-footer">
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent mb-3"></div>
        <span class="text-gray-500">第 1 页</span>
      </footer>
    </div>

    <!-- 第二页 -->
    <div class="report-page">
      <!-- 页眉 -->
      <header class="page-header">
        <div class="flex justify-between items-center px-4 pb-3">
          <h1 class="text-base font-medium text-gray-900">睡眠分析报告</h1>
          <span class="text-black/80 text-sm">{{ formatChineseDate(selectedDate) }}</span>
        </div>
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent"></div>
      </header>

      <!-- 第二页内容 -->
      <div class="page-content space-y-4">
        <!-- 呼吸健康 Card -->
        <div class="content-section">
  <!-- Breathing Health -->
  <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
    <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
      <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
      呼吸健康
    </h2>
    
    <div class="flex flex-col md:flex-row gap-2">
      <!-- 左侧统计信息 -->
      <div class="w-full md:w-1/4">
        <div class="grid grid-cols-1 gap-2">
          <div v-for="(item, index) in computedBreathingHealth" 
               :key="index"
               class="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 hover:shadow-sm transition-all"
          >
            <span class="text-sm text-gray-500">{{ item.label }}</span>
            <span class="text-base font-medium text-gray-900">{{ item.value }}</span>
          </div>
        </div>
      </div>

      <!-- 右侧图表区域 -->
      <div class="w-full md:w-3/4 space-y-2">
        <!-- 呼吸暂停事件图表 -->
        <div class="bg-white rounded-lg border border-gray-100">
          <h3 class="text-sm font-medium px-3 pt-2 text-gray-500">晚呼吸暂停低通气事件</h3>
          <div class="h-[140px]">
            <div :id="AhChartId" class="w-full h-full"></div>
          </div>
        </div>

        <!-- 鼾声分析图表 -->
        <div class="bg-white rounded-lg border border-gray-100">
          <h3 class="text-sm font-medium px-3 pt-2 text-gray-500">鼾声分析</h3>
          <div class="h-[140px]">
            <div :id="SnoreChartId" class="w-full h-full"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>

        <!-- 睡眠期生理指标 Card -->
        <div class="content-section">
 <!-- Sleep Period Indicators & Charts -->
 <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
  <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
    <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
    睡眠期生理指标
  </h2>
  
  <div class="space-y-4">
    <!-- 指标卡片 -->
    <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
      <div v-for="(item, index) in computedSleepPeriodIndicators" 
           :key="index"
           class="flex flex-col p-2.5 bg-white rounded-lg border border-gray-200 hover:border-blue-300 transition-colors text-center"
      >
        <span class="text-sm text-gray-500 mb-1">{{ item.label }}</span>
        <div class="flex items-center justify-center gap-1">
          <span class="text-base font-medium text-gray-900">{{ item.value }}</span>
          <span v-if="item.trend" 
                class="text-xs"
                :class="{
                  'text-green-500': item.trend > 0,
                  'text-red-500': item.trend < 0,
                  'text-gray-400': item.trend === 0
                }"
          >
            {{ item.trend > 0 ? '↑' : item.trend < 0 ? '↓' : '–' }}
          </span>
        </div>
      </div>
    </div>

    <!-- 图表区域 -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
      <!-- 睡眠心率变异性 -->
      <div class="bg-white rounded-lg border border-gray-100">
        <h3 class="text-sm font-medium px-3 py-2 border-b border-gray-100">睡眠心率变异性</h3>
        <div class="h-[140px] p-2">
          <div :id="HrvChartId" class="w-full h-full"></div>
        </div>
      </div>

      <!-- 睡眠呼吸频率 -->
      <div class="bg-white rounded-lg border border-gray-100">
        <h3 class="text-sm font-medium px-3 py-2 border-b border-gray-100">睡眠呼吸频率</h3>
        <div class="h-[140px] p-2">
          <div :id="RrChartId" class="w-full h-full"></div>
        </div>
      </div>

      <!-- 睡眠心率 -->
      <div class="bg-white rounded-lg border border-gray-100">
        <h3 class="text-sm font-medium px-3 py-2 border-b border-gray-100">睡��心率</h3>
        <div class="h-[140px] p-2">
          <div :id="HrChartId" class="w-full h-full"></div>
        </div>
      </div>

      <!-- 血氧 -->
      <div class="bg-white rounded-lg border border-gray-100">
        <h3 class="text-sm font-medium px-3 py-2 border-b border-gray-100">血氧</h3>
        <div class="h-[140px] p-2">
          <div :id="spo2ChartId" class="w-full h-full"></div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>

        <!-- 睡眠环境 Card -->
        <div class="content-section">

        <!-- Sleep Environment -->
        <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
          <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
            <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
            睡眠环境
          </h2>
          
          <div class="flex flex-col md:flex-row gap-2">
            <!-- 左侧环境指标 -->
            <div class="w-full md:w-1/3">
              <div class="grid grid-cols-1 gap-2">
                <div v-for="(item, index) in computedSleepEnv" 
                     :key="index"
                     class="flex items-center justify-between p-2.5 bg-white rounded-lg border border-gray-200 hover:border-blue-300 transition-colors"
                >
                  <div class="flex items-center gap-2">
                    <span class="text-sm text-gray-500">{{ item.label }}</span>
                    <span v-if="item.description" 
                          class="text-xs text-gray-400 hidden md:inline"
                          :title="item.description"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </div>
                  <span class="text-base font-medium text-gray-900">{{ item.value }}</span>
                </div>
              </div>
            </div>
  
            <!-- 右侧图表 -->
            <div class="w-full md:w-2/3">
              <div class="bg-white rounded-lg border border-gray-100">
                <div class="h-[140px]">
                  <div :id="EnvChartId" class="w-full h-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
          </div>
      </div>

      <!-- 页脚 -->
      <footer class="page-footer">
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent mb-3"></div>
        <span class="text-gray-500">第 2 页</span>
      </footer>
    </div>

    <!-- 第三页和第四页（月趋势） -->
    <div class="report-page">
      <!-- 页眉 -->
      <header class="page-header">
        <div class="flex justify-between items-center px-4 pb-3">
          <h1 class="text-base font-medium text-gray-900">睡眠分析报告</h1>
          <span class="text-black/80 text-sm">{{ formatChineseDate(selectedDate) }}</span>
        </div>
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent"></div>
      </header>

      <!-- 月趋势内容第一部分 -->
      <div class="page-content">
        <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
          <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
            <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
            月趋势
          </h2>
          
          <!-- 使用grid布局将图表分为两列 -->
          <div class="grid grid-cols-2 gap-4">
            <!-- 总睡眠时间 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">总睡眠时间</h5>
              <div class="h-36">
                <div :id=TstChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 睡速 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">入睡速度</h5>
              <div class="h-36">
                <div :id=solChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 睡眠规律性 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">睡眠规律性</h5>
              <div class="h-36">
                <div :id=sriChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 睡眠中断时长 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">睡眠中断时长</h5>
              <div class="h-36">
                <div :id=wasoChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 睡眠效率 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">睡眠效率</h5>
              <div class="h-36">
                <div :id=seChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 快速眼动和深睡长 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">快速眼动和深睡时长</h5>
              <div class="h-36">
                <div :id=deepChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 各睡眠阶段的时长 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">各睡眠阶段的时长</h5>
              <div class="h-36">
                <div :id=StageCompChartId class="w-full h-full"></div>
              </div>
            </div>
  
            <!-- 呼吸暂停低通指数 -->
            <div class="trend-chart-container">
              <h5 class="text-sm font-semibold mb-2">呼吸暂停低通指数（AHI）</h5>
              <div class="h-36">
                <div :id=ahiChartId class="w-full h-full"></div>
              </div>
            </div></div>
                </div>

      <!-- 页脚 -->
      <footer class="page-footer">
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent mb-3"></div>
        <span class="text-gray-500">第 3 页</span>
      </footer>
    </div>

   
  </div>
    <div class="report-page">
      <!-- 页眉 -->
      <header class="page-header">
        <div class="flex justify-between items-center px-4 pb-3">
          <h1 class="text-base font-medium text-gray-900">睡眠分析报告</h1>
          <span class="text-black/80 text-sm">{{ formatChineseDate(selectedDate) }}</span>
        </div>
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent"></div>
      </header>

      <!-- 月趋势内容第二部分 -->
      <div class="page-content">
        <div class="bg-white rounded-lg shadow-sm p-3 border border-gray-100">
        <h2 class="text-sm font-bold text-gray-900 mb-1.5 flex items-center">
          <span class="w-1 h-4 bg-blue-600 rounded-full mr-2"></span>
          月趋势
        </h2>
                <!-- 使用grid布局将图表分为两列 -->
                <div class="grid grid-cols-2 gap-4">
             <!-- 鼾声指数 -->
             <div class="trend-chart-container">
            <h5 class="text-sm font-semibold mb-2">鼾声指数</h5>
            <div class="h-36">
              <div :id=SnoreIndexChartId class="w-full h-full"></div>
            </div>
          </div>
          
          <!-- 睡眠心率变异性 -->
          <div class="trend-chart-container">
            <h5 class="text-sm font-semibold mb-2">睡眠心率变异性</h5>
            <div class="h-36">
              <div :id=hrvBLChartId class="w-full h-full"></div>
            </div>
          </div>

          <!-- 睡眠呼吸频率 -->
          <div class="trend-chart-container">
            <h5 class="text-sm font-semibold mb-2">睡眠呼吸频率</h5>
            <div class="h-36">
              <div :id=rrBLChartId class="w-full h-full"></div>
            </div>
          </div>

          <!-- 睡眠心率 -->
          <div class="trend-chart-container">
            <h5 class="text-sm font-semibold mb-2">睡眠心率</h5>
            <div class="h-36">
              <div :id=hrBLChartId class="w-full h-full"></div>
            </div>
          </div>

          <!-- 睡眠环境 -->
          <div class="trend-chart-container">
            <h5 class="text-sm font-semibold mb-2">睡眠环境</h5>
            <div class="h-36">
              <div :id=envChartId class="w-full h-full"></div>
            </div>
          </div>
          </div>  
        </div>
      </div>

      <!-- 页脚 -->
      <footer class="page-footer">
        <div class="h-[1px] bg-gradient-to-r from-transparent via-gray-300 to-transparent mb-3"></div>
        <span class="text-gray-500">第 4 页</span>
      </footer>
    </div>
  </div>
</template>
<script setup>
  import {onMounted, ref, watch, computed, onBeforeUnmount, nextTick} from 'vue'
  import {createAxiosInstance, wdb_get_history_new} from "@/plugins/axios.js";

  import 'v-calendar/style.css'
  import {request_data} from "@/plugins/axios.js";
  import * as echarts from 'echarts'
  import {setStageChartOption} from "@/plugins/chart/stage_chart";
  import {setArtChartOption} from "@/plugins/chart/day_art_chart";
  import {setArt2ChartOption} from "@/plugins/chart/day_art_chart2";
  import {setDayEnvChartOption} from "@/plugins/chart/day_env_chart";
  import {setDayHRVChartOption} from "@/plugins/chart/day_hrv_chart";
  import {setDayRRChartOption} from "@/plugins/chart/day_rr_chart";
  import {setStageAHChartOption} from "@/plugins/chart/stage_ah_chart";
  import {setDayHRChartOption} from "@/plugins/chart/day_hr_chart";
  import {setStageAH2ChartOption} from "@/plugins/chart/stage_ah_chart2";
  import {chart_dates, resetDates} from "@/plugins/chart/variables";
  import {formatDateString} from "@/plugins/utils/utils";
  import {setSpO2ChartOption} from "@/plugins/chart/spo2_chart";
  import {setDaySnoreChartOption} from "@/plugins/chart/day_snore_chart";
  import {setTSTChartOption} from "@/plugins/chart/tst_chart";
  import {setStageCompChartOption} from "@/plugins/chart/stage_comp_chart";
  import {setSnoreIndexChartOption} from "@/plugins/chart/snore_index_chart";
  import {setSOLChartOption} from "@/plugins/chart/sol_chart";
  import {setWASOChartOption} from "@/plugins/chart/waso";
  import {setDeepChartOption} from "@/plugins/chart/deep_time_chart";
  import {setEnvChartOption} from "@/plugins/chart/env_chart";
  import {setSEChartOption} from "@/plugins/chart/tst_chart";
  import {setTIBChartOption} from "@/plugins/chart/tib_chart";
  import {setHRVChartOption} from "@/plugins/chart/hrv_chart";
  import {setRRChartOption} from "@/plugins/chart/rr_chart";
  import {setHRChartOption} from "@/plugins/chart/hr_chart";
  import {setAHIChartOption} from "@/plugins/chart/ahi_month_chart";
  
  const isLoading = ref(true)
  
  const props = defineProps({
    device: String,
    selections: Array,
    date: String,
    stage: String,
  
  });
  const keyMap = {
    reporttime: "报告开始计算时间",
    costtime: "报告生成总耗时",
    startc: "数据开始时间",
    endc: "数据结束时间",
    dur: "数据总时长",
    snore_index: "打鼾指数",
    snore_index_onback: "仰卧打鼾指数",
    snore_index_onside: "侧卧打鼾指数",
    posion_onback_time: "仰卧时长",
    posion_onback_rate: "仰卧占比",
    posion_onside_time: "侧卧时长",
    posion_onside_rate: "侧卧占比",
  
    sigtype_0: "无信号",
    sigtype_1: "低信号",
    sigtype_2: "正常信号",
    sigtype_3: "微体动信号",
    sigtype_4: "强体动信号",
    onbedlevel: "在床阈值",
    err_log: "错误信息",
    avg_temp: "平均温度",
    avg_humi: "平均湿度",
    avg_illu: "睡眠时平光照",
    high_illu: "强光照睡眠",
    sleep_time: "有效睡眠总时间",
    ahi_count: "呼吸事件总数",
    ahi: "综合AHI",
    long_artifact: "体动次数",
    short_artifact: "微体动次数",
    sri: "SRI",
    quality: "睡眠质量分",
    qualitylogs: "睡眠质量细则",
    sleep_dur_0: "睡眠分期_体动和无效时长",
    ahi_count_0: "体动呼吸事件次数（无效）",
    sleep_dur_1: "睡眠分期_Wake时",
    ahi_count_1: "Wake呼吸事件次数（无效）",
    sleep_dur_2: "睡眠分期_REM时长",
    ahi_count_2: "REM期呼事件次数",
    ahi_2: "REM期呼吸指数",
    sleep_dur_3: "睡眠分期_N1时长",
    sleep_dur_4: "睡眠分期_N2时长",
    sleep_dur_5: "睡眠分期_N3时长",
    ahi_count_345: "NREM期呼吸事件次数",
    ahi_345: "NREM期呼吸指数",
    odi_count: "ODI次数",
    odi: "ODI指数",
    min_spo2: "最低血饱和度",
    mean_spo2: "平均血氧饱和度",
    hrv_rmssd_mean: "hrv_rmssd",
    hrv_morning: "hrv_morning",
    hrv_evening: "hrv_evening",
    hrv_delta: "hrv_delta",
    hrv_ratio_mean: "hrv_ratio",
    sleep_range: "睡眠区间调试信息",
    push_message: "push消息",
  };
  const selectedDevice = ref(props.device)
  const selectedReportDate = ref(props.date); // 选中的告日期
  console.log(selectedReportDate.value) 
  const reports = ref({}); // 告列表
  const reportDates = ref([]); // 报告日期列表
  const selections = ref(props.selections)
  const userinfo = ref(selections.value['userinfo'])
  
  const deviceinfo = ref(selections.value['deviceinfo'])
  const summmary = ref({})
  const endData = ref({})
  const data = ref({})
  const chartId = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
  const ArtChartId = ref(`art_chart_${Math.random().toString(36).substr(2, 9)}`);
  const EnvChartId = ref(`env_chart_${Math.random().toString(36).substr(2, 9)}`);
  const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
  const RrChartId = ref(`rr_chart_${Math.random().toString(36).substr(2, 9)}`);
  const AhChartId = ref(`ah_chart_${Math.random().toString(36).substr(2, 9)}`);
  const HrChartId = ref(`hr_chart_${Math.random().toString(36).substr(2, 9)}`);
  const rrBLChartId = ref(`rr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
  const hrBLChartId = ref(`hr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
  const hrvBLChartId = ref(`hrv_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
  const ahiChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);
  const spo2ChartId = ref(`spo2_chart_${Math.random().toString(36).substr(2, 9)}`);
  const SnoreChartId = ref(`snore_chart_${Math.random().toString(36).substr(2, 9)}`);
  const TstChartId = ref(`tst_chart_${Math.random().toString(36).substr(2, 9)}`);
  const StageCompChartId = ref(`stage_comp_chart_${Math.random().toString(36).substr(2, 9)}`);
  const SnoreIndexChartId = ref(`snore_index_chart_${Math.random().toString(36).substr(2, 9)}`);
  const solChartId = ref(`sol_chart_${Math.random().toString(36).substr(2, 9)}`);
  const wasoChartId = ref(`waso_chart_${Math.random().toString(36).substr(2, 9)}`);
  const seChartId = ref(`se_chart_${Math.random().toString(36).substr(2, 9)}`);
  const envChartId = ref(`env_month_chart_${Math.random().toString(36).substr(2, 9)}`);
  const deepChartId = ref(`deep_chart_${Math.random().toString(36).substr(2, 9)}`);
  const sriChartId = ref(`sri_chart_${Math.random().toString(36).substr(2, 9)}`);
  const reports_data = ref([])
  const selectedDate = ref(null)
  
  // 判断是否有报告
  const hasReport = (date) => {
    return reportDates.value.some(reportDate =>
        new Date(reportDate).toDateString() === new Date(date).toDateString()
    )
  }
  
  const disableDate = (date) => {
    // 可选择禁用没��报告的日期
    return !hasReport(date)
  }
  // 日期配置
  
  // 计算最早和最晚的报告日期
  const minDate = computed(() => {
    if (reportDates.value.length === 0) return null
    return new Date(Math.min(...reportDates.value.map(d => new Date(d))))
  })
  
  const maxDate = computed(() => {
    if (reportDates.value.length === 0) return null
    return new Date(Math.max(...reportDates.value.map(d => new Date(d))))
  })
  
  // 修改用日期的定义
  const disabledDates = computed(() => {
    // 生成一个日期范围内的所有日期
    const start = minDate.value
    const end = maxDate.value
    const allDates = []
  
    if (start && end) {
      const current = new Date(start)
      while (current <= end) {
        const dateString = current.toISOString().split('T')[0]
        if (!reportDates.value.includes(dateString)) {
          allDates.push(new Date(current))
        }
        current.setDate(current.getDate() + 1)
      }
    }
  
    return allDates
  })
  
  // 日历标记
  const attributes = computed(() => [
    {
      dates: reportDates.value.map(date => new Date(date)),
      dot: {
        color: 'green',
        class: 'report-dot'
      },
      popover: {
        label: '有报告'
      }
    }
  ])
  
  //更新数据
  function updateEndData() {
    const mappedData = {};
    data.value = reports.value['online']
    // 分配映射的数据
    if (data.value) {
      Object.keys(keyMap).forEach(key => {
        if (key in data.value) {
          mappedData[keyMap[key]] = data.value[key];
        }
      })
    }
    // 添加'其他'分类
    endData.value = mappedData;
  }
  
  //监听日期 选择被试
  watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
    if (newDevice == "" || newDevice != oldDevice) {
    } else if (oldDate !== newDate) {
      await getReports()
  
    }
  }, {immediate: true});
  //监听日期
  watch(() => props.date, (newVal) => {
    // 当数据变化时，重新渲染图表
    selectedReportDate.value = newVal
    selectedDate=selectedReportDate.value
  
    getReports()
  
  }, {deep: true});
  watch(() => props.device, (newVal) => {
    // 当数据变化时，重新渲染图表
    selectedDevice.value = newVal
    changeDate()
    getReports()
  }, {deep: true});
  watch(() => props.selections, (newVal) => {
    // 当数据变化时，重新渲染图表
    selections.value = newVal
    userinfo.value = selections.value['userinfo']
    deviceinfo.value = selections.value['deviceinfo']
    getReports()
  }, {deep: true});
  
  async function changeDate() {
    reportDates.value = []
    if(selectedReportDate.value){
     return       await getReports()

    }
     selectedReportDate.value = ""
    const response = await request_data(`/list_reports/${selectedDevice.value}/`, '计算服务器1');
    reportDates.value = response.nodes;
    if (response.nodes.length > 0 && selectedReportDate.value === '') {
      selectedReportDate.value = response.nodes[0];
      await getReports()
  
    }
    return response
  }
  
  async function getReportList(reportdate) {
    try {
      // 确保 reportdate 是有效的日期字符串或时间戳
      if (!reportdate) {
        return;
      }
  
      // 将 reportdate 转换为 Date 对象并验证
      let selectedDate = new Date(reportdate);
      if (isNaN(selectedDate.getTime())) {
        return;
      }
  
      // 计算起始日期和结束日期
      let endDate = new Date(selectedDate);
      let startDate = new Date(selectedDate);
      startDate.setDate(selectedDate.getDate() - 29);
  
      // 验证期范围
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        console.error('Invalid date range');
        return;
      }
  
      // 格式化日期为 yyyy-mm-dd
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
  
      let formattedStartDate = formatDate(startDate);
      let formattedEndDate = formatDate(endDate);
  
      // 验证 selectedDevice
      if (!selectedDevice?.value) {
        console.error('Selected device is required');
        return;
      }
  
      // 发送请求
      let response = await request_data(
          `/deviceallreport/${selectedDevice.value}/${formattedStartDate}/${formattedEndDate}/`,
          '计算服务器1'
      );
  
      // 验证响应数
      if (response) {
        reports_data.value = response;
      } else {
        console.error('No response data received');
      }
    } catch (error) {
      console.error('Error in getReportList:', error);
      // 这里可以添加错误处理逻辑，比如显示错误消息给用户
    }
  }
  
  
  // 修改获取数据和初始化图表的方法
  async function getReports() {
    try {
      isLoading.value = true
      if (!props.device) return
  
      const reportdate = selectedReportDate.value ? selectedReportDate.value : "_";
      reports.value = await request_data(`/devicereport/${selectedDevice.value}/${reportdate}/`, "计算服务器1");
      await getReportList(selectedReportDate.value)
  
      if (reports.value['summary']) {
        summmary.value = reports.value['summary']
        updateEndData()
        // 确保 DOM 已更新后再初始化图表
        await nextTick()
        await updateCharts()
      }
    } catch (error) {
      console.error('Error fetching reports:', error)
    } finally {
      isLoading.value = false
    }
  }
  // 监听 selectedReportDate 变化
  watch(() => selectedReportDate.value, (newVal) => {
    if (newVal) {
      selectedDate.value = new Date(newVal)
    }
  }, { immediate: true })
  
  
  onMounted(() => {
    changeDate()
  });
  const formatTime = (timestamp) => {
    if (!timestamp) return '--:--';
  
    try {
      const date = new Date(timestamp * 1000);
  
      // 检查是否是有效日期
      if (isNaN(date.getTime())) {
        return '--:--';
      }
  
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
  
      return `${hours}:${minutes}`;
    } catch (error) {
      console.error('时间格式化错误:', error);
      return '--:--';
    }
  }
  
  function formatMinutes(minutes) {
    if (Number.isFinite(minutes)) {  // 查minutes是否为一个有限的数
      const roundedMinutes = Math.round(minutes);
      const hours = Math.floor(roundedMinutes / 60);
      const remainingMinutes = roundedMinutes % 60;
  
      if (hours === 0) {
        return `${remainingMinutes} 分钟`;
      }
  
      return `${hours} 时 ${remainingMinutes} 分`;
    }
    return '';
  }
  
  const getSleepEfficiency = (tst, tib) => {
    if (!tst || !tib) return '--'
    return ((tst / tib) * 100).toFixed(0)
  }
  // 计算属性
  const computedSleepAnalysis = computed(() => {
    if (!data.value) return []
  
    return [
      // 时间相关
      {label: '关灯时间', value: formatTime(data.value.sleep_intention_time)},
      {label: '开灯时间', value: formatTime(data.value.intention_leave_time)},
      {label: '总睡眠时长,TST', value: formatMinutes(data.value.tst)},
      {label: '睡眠效率,SE', value: `${getSleepEfficiency(data.value.tst, data.value.tib)}%`},
      
      // 睡眠质量相关
      {label: '入睡时间,SOL', value: formatMinutes(data.value.sol)},
      {label: '睡眠中断,WASO', value: formatMinutes(data.value.waso)},
      {label: '深度睡眠', value: formatMinutes(data.value.deep_time)},
      {label: '快速眼动潜伏期,Rem Latency', value: formatMinutes(data.value.rem_latency)},
      
      // 睡眠评分相
      {label: '睡眠规律性,SRI', value: (data.value.sri != null && data.value.sri >= 0) ? `${data.value.sri}%` : '--'},
      {label: '睡眠碎片化,SFI', value: data.value.sfi?.toFixed(1) || '--'}
    ]
  })
  
  const computedSleepPhases = computed(() => {
    if (!data.value) return []
  
    const totalTime = data.value.tst || 0
    return [
      {
        label: '清醒',
        value: formatMinutes(data.value.sleep_dur_1),
        percentage: data.value.sleep_dur_1_efficiency ? `${((data.value.sleep_dur_1 / totalTime) * 100).toFixed(0)}%` : '--'
      },
      {
        label: '快速眼动睡眠',
        value: formatMinutes(data.value.rem_time),
        percentage: data.value.rem_efficiency ? `${(data.value.rem_efficiency * 100).toFixed(0)}%` : '--'
      },
      {
        label: '浅度睡眠',
        value: formatMinutes(data.value.core_time),
        percentage: data.value.core_efficiency ? `${(data.value.core_efficiency * 100).toFixed(0)}%` : '--'
      },
      {
        label: '深度睡眠',
        value: formatMinutes(data.value.deep_time),
        percentage: data.value.deep_efficiency ? `${(data.value.deep_efficiency * 100).toFixed(0)}%` : '--'
      }
    ]
  })
  
  const computedBreathingHealth = computed(() => {
    if (!data.value) return []
  
    return [
      {label: '呼吸暂停低通指数,AHI', value: data.value.ahi?.toFixed(1) || '--'},
      {label: 'NREM AHI', value: data.value.ahi_345?.toFixed(1) || '--'},
      {label: 'REM AHI', value: data.value.ahi_2?.toFixed(1) || '--'},
      {label: '鼾声指数,SI', value: data.value.bcgsnore_index?.toFixed(1) || '--'},
      {
        label: '睡眠平均呼吸率',
        value: data.value.avg_resp ? `${data.value.avg_resp.toFixed(1)}次/分` : '--'
      }
    ]
  })
  
  
  const computedSleepPeriodIndicators = computed(() => {
    if (!data.value) return []
  
    return [
      {
        label: '睡眠最低心率',
        value: summmary.value.min_sleep_heart_rate ? `${summmary.value.min_sleep_heart_rate}次/分` : '--'
      },
      {
        label: '睡眠平均心率',
        value: summmary.value.avg_sleep_heart_rate ? `${summmary.value.avg_sleep_heart_rate}次/分` : '--'
      },
      {label: '睡眠期HRV_RMSSD', value: summmary.value.avg_hrv_rmssd ? `${summmary.value.avg_hrv_rmssd}毫秒` : '--'},
      {label: '睡眠期HRV_LF/HF', value: summmary.value.avg_hrv_radio?.toFixed(1) || '--'}
    ]
  })
  const computedSleepEnv = computed(() => {
    if (!data.value) return []
    const avg_temp = Math.round(parseFloat(data.value.avg_temp))
    const avg_humi = Math.round(parseFloat(data.value.avg_humi))
    const avg_illu = Math.round(parseFloat(data.value.avg_illu))
    return [
      {
        label: '平均温度',
        value: `${avg_temp}°C`
      },
      {
        label: '平均湿度',
        value: `${avg_humi}%`
      },
      {label: '光照影响的睡眠比例', value: `${avg_illu}%`},
    ]
  })
  // 处理窗口大小变化
  const handleResize = () => {
    chartInstances.value.stage?.resize()
    chartInstances.value.art?.resize()
  }
  
  // 销毁图表实例
  const destroyCharts = () => {
    chartInstances.value.stage?.dispose()
    chartInstances.value.art?.dispose()
    chartInstances.value = {
      stage: null,
      art: null
    }
  }
  // 图表引用
  const chartRefs = ref({})
  const chartInstances = ref({})
  // 初始化所有图表
  const initCharts = () => {
    const chartIds = {
      stage: chartId.value,
      art: ArtChartId.value,
      hr: HrChartId.value,
      hrv: HrvChartId.value,
      rr: RrChartId.value,
      spo2: spo2ChartId.value,
      ah: AhChartId.value,
      snoreIndex: SnoreIndexChartId.value,
      env: envChartId.value,
      env_day: EnvChartId.value,
      tst: TstChartId.value,
      stageComp: StageCompChartId.value,
      deep: deepChartId.value,
      se: seChartId.value,
      sol: solChartId.value,
      waso: wasoChartId.value,
      snore: SnoreChartId.value,
      sri: sriChartId.value,
      ahi: ahiChartId.value,
      rrbl: rrBLChartId.value,
      hrbl: hrBLChartId.value,
      hrvbl: hrvBLChartId.value,
    }
  
    // 确保先销毁现有实例
    destroyCharts()
  
    // 重新初始化图表
    Object.entries(chartIds).forEach(([key, id]) => {
      const el = document.getElementById(id)
      if (el) {
        const chart = echarts.init(el)
        chartInstances.value[key] = chart
        
        // 设图表响应容器大小
        chart.setOption({
          grid: {
            containLabel: true,
            left: '3%',
            right: '3%'
          }
        })
      }
    })
  }
  
  
  // 更所有图表
  const updateCharts = async () => {
    if (!Object.keys(data.value).length) return
    // 确保 DOM 已更新
    await nextTick()
    // 如果图表实例不存在，重新初始化
    if (Object.keys(chartInstances.value).length === 0) {
      initCharts()
    }
    initChartDates(reports_data.value, selectedReportDate.value)
  
    parseReportsToDisplay(reports_data.value)
    const baseData = {
      start: data.value['starttime'],
      end: data.value['endtime'],
      stages: data.value['stage_details'],
      nointention: (data.value['intention_middle'] && data.value['intention_middle'].length > 0) ? data.value['intention_middle'] : null,
      leavebed: (data.value['leavebed'] && data.value['leavebed'].length > 0) ? data.value['leavebed'] : null,
    }
    setTimeout(() => {
  
      // 更新每个图表
      Object.entries(chartInstances.value).forEach(([key, chart]) => {
        if (!chart) return
  
        try {
          switch (key) {
            case 'stage':
              setStageChartOption(chart, {...baseData})
              break
            case 'art':
              // 根据数据类型选择不同的渲染方式
              if (data.value['artifact_series']) {
                setArt2ChartOption(chart, {
                  ...baseData,
                  segments: data.value['artifact_series']
                })
              } else {
                setArtChartOption(chart, {
                  ...baseData,
                  segments: data.value['artifact_series']
                })
              }
              break
            case 'hr':
              setDayHRChartOption(chart, {
                ...baseData,
                segments: data.value.bpm_series,
                avg: data.value.bpm_avg
              })
              break
            case 'hrv':
              setDayHRVChartOption(chart, {
                ...baseData,
                segments: data.value.hrv_rmssd,
                avg: data.value.hrv_rmssd_mean
              })
              break
            case 'rr':
              setDayRRChartOption(chart, {
                ...baseData,
                segments: data.value.resp_series,
                avg: data.value.avg_resp
              })
              break
            case 'spo2':
              setSpO2ChartOption(chart, {
                ...baseData,
                spo2: data.value.spo2_data
              })
              break
            case 'ah':
              if (data.value.ahi_series) {
                setStageAH2ChartOption(chart, {
                  ...baseData,
                  ah: data.value['ahi_series'] || data.value['ahi_events']
                })
              } else {
  
                setStageAHChartOption(chart, {
                  ...baseData,
                  ah: data.value.ahi_events
                })
              }
              break
            case 'snoreIndex':
              setSnoreIndexChartOption(reports_data.value, chart, chart_dates);
  
              break
            case 'env':
              setEnvChartOption(reports_data.value, chart, chart_dates)
              break
            case 'env_day':
              setDayEnvChartOption(chart, {
                ...baseData,
                segments: data.value.thi_series
              })
              break
              // 趋势图表
            case 'tst':
              setTSTChartOption(reports_data.value, chart, chart_dates)
              break
            case 'stageComp':
              setStageCompChartOption(reports_data.value, chart, chart_dates)
              break
            case 'deep':
              setDeepChartOption(reports_data.value, chart, chart_dates)
              break
            case 'se':
              setSEChartOption(reports_data.value, chart, chart_dates)
              break
            case 'sol':
              setSOLChartOption(reports_data.value, chart, chart_dates)
              break
            case 'waso':
              setWASOChartOption(reports_data.value, chart, chart_dates)
              break
            case 'ahi':
              setAHIChartOption(reports_data.value, chart, chart_dates)
              break
            case 'rrbl':
              setRRChartOption(reports_data.value, chart, chart_dates)
              break
            case 'hrbl':
              setHRChartOption(reports_data.value, chart, chart_dates)
              break
            case 'hrvbl':
              setHRVChartOption(reports_data.value, chart, chart_dates)
              break
            case 'snore':
              setDaySnoreChartOption(chart, {
                ...baseData,
                snore: data.value['bcgsnore_events']
              })
              break
            case 'sri':
              setTIBChartOption(reports_data.value, chart, chart_dates);
              break
          }
        } catch (error) {
          console.error(`更新图表 ${key} 失败:`, error)
        }
        finally{
          isLoading.value = false
  
        }
      })
    }, 100)
  }
  
  const parseReportsToDisplay = (reports) => {
    //console.log("History:parseReportsToDisplay");
  
    // 传入了报告列表，是长时间趋势图模式
    var i;
    if (reports && reports.length > 0) {
      this.setData({
        reportCount: reports ? reports.length : 0,
      });
      let max_chart_days = 0
      if (this.data.period === 'week') {
        max_chart_days = 7;
      } else if (this.data.period === 'month') {
        max_chart_days = 30;
      } else if (this.data.period === 'year') {
        max_chart_days = 365;
      }
  
      //生成空的日期序列，用于各图表的日期轴
      var today = new Date(reports[0].end * 1000); //TODO：注意：这里使用第一份有效报告作为横轴最新节点，可能会导致跟取数据库的时间区间对不齐
      today.setHours(0, 0, 0, 0);
      var todayTS = today.getTime() / 1000; //按今天零点对齐
      resetDates(); //先清空
      for (i = 0; i < max_chart_days; i++) {
        var shortDate = formatDateString(todayTS * 1000, "yyyyMMdd", false);
        chart_dates[shortDate] = -1;
        todayTS -= 24 * 3600;
      }
      //在空日期序列中填充有效报告数据的索引
      todayTS = today.getTime() / 1000; //重新按今天零点对齐
      reports.forEach((value, index, array) => {
        var shortDate = formatDateString(value.end * 1000, "yyyyMMdd", false);
        if (chart_dates[shortDate] === -1) {
          chart_dates[shortDate] = index;
        } else if (chart_dates[shortDate] >= 0) {
          let idx = chart_dates[shortDate];
          if (array[idx].report_time < value.report_time) {
            chart_dates[shortDate] = index;
          }
        }
      });
      //去掉日期序列中开头的空白段
      var keysDate = Object.keys(chart_dates).reverse();
      for (i = 0; i < keysDate.length; i++) {
        if (chart_dates[keysDate[i]] >= 0) {
          break;
        } else if (i < keysDate.length - 29) { // 保留后 14 天，哪怕空白，为了图表不要太短
          delete chart_dates[keysDate[i]];
        }
      }
      //console.log(chart_dates);
  
    }
  }
  
  function initChartDates(reports, reportKey = '') {
    if (!reports) return;
  
    const max_chart_days = 30;
    let today;
  
    try {
      if (reportKey) {
        today = new Date(reportKey);
        if (isNaN(today.getTime())) {
          throw new Error('Invalid date');
        }
      } else {
        today = new Date();
      }
  
      today.setHours(0, 0, 0, 0);
      let todayTS = today.getTime() / 1000;
      resetDates();
  
      for (let i = 0; i < max_chart_days; i++) {
        const shortDate = formatDateString(todayTS * 1000, 'MMdd', false);
        chart_dates[shortDate] = -1;
        todayTS -= 24 * 3600;
      }
    } catch (error) {
      console.error('Error initializing chart dates:', error);
    }
  }
  
  // 生命周期钩子
  onMounted(async () => {
    try {
      isLoading.value = true
      await changeDate()
      await nextTick()
      fetchReportInfo()
      window.addEventListener('resize', handleResize)
    } finally {
      // isLoading.value = false
    }
  })
  // onMounted(() => {
  //   initCharts()
  //   updateCharts()
  //   window.addEventListener('resize', handleResize)
  // })
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
    destroyCharts()
  })
  
  // 监听数��变化
  watch(() => data.value, updateCharts, {deep: true})
  watch(() => reports_data.value, updateCharts, {deep: true})
  
  // 添加一个函数来计算进度条宽度
  const getProgressWidth = (item) => {
    // 根据不同类型的数据返回不同的进度值
    if (item.label.includes('度')) {
      // 假设适宜温度范围是18-26度
      const temp = parseFloat(item.value)
      return `${Math.min(100, Math.max(0, ((temp - 18) / (26 - 18)) * 100))}%`
    }
    if (item.label.includes('湿度')) {
      // 假设适宜湿度范围是30-70%
      const humidity = parseFloat(item.value)
      return `${Math.min(100, (humidity / 100) * 100)}%`
    }
    if (item.label.includes('光照')) {
      // 直接使用百分比值
      const light = parseFloat(item.value)
      return `${Math.min(100, light)}%`
    }
    return '50%' // 默认值
  }

// 添加日期格式化函数
const formatChineseDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${year}年 ${month.toString().padStart(2, '0')}月 ${day.toString().padStart(2, '0')}日`;
}

// 添加日期格式化工具函数
const formatDateValue = (dateValue) => {
  if (!dateValue) return '';
  
  try {
    // 处理时间戳（��设是秒级时间戳）
    if (typeof dateValue === 'number') {
      const date = new Date(dateValue * 1000);
      return date.toISOString().split('T')[0];
    }
    
    // 处理 YYYY-MM-DD 格式的字符串
    if (typeof dateValue === 'string' && dateValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateValue;
    }
    
    // 处理其他日期字符串
    const date = new Date(dateValue);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    }
    
    return '';
  } catch (error) {
    console.error('日期格式化错误:', error);
    return '';
  }
};

const fetchReportInfo = async () => {
  // try {
    const axiosInstance = createAxiosInstance('计算服务器1');
    
    // 格式化日期
    let formattedDate;
    if (typeof selectedReportDate.value === 'string') {
      formattedDate = selectedReportDate.value;
    } else if (selectedReportDate.value instanceof Date) {
      formattedDate = selectedReportDate.value.toISOString().split('T')[0];
    } else {
      const date = new Date(selectedReportDate.value);
      formattedDate = date.toISOString().split('T')[0];
    }
    const response = await axiosInstance.get(`report_info/${props.device}/${formattedDate}/`);
    console.log(response)
    // 检查 response.data.data 是否为 null
    if (response.data.status === 'success' && response.data.data) {
      const { basicInfo, customFields: customFieldsData } = response.data.data;
      
      // 更新 userinfo
      userinfo.value = {
        username: basicInfo.name || '',
        sex: basicInfo.gender || '',
        ethnicity: basicInfo.ethnicity || '',
        birthday: formatDateValue(basicInfo.birthDate),
        age: basicInfo.age || '',
        height: basicInfo.height || null,
        weight: basicInfo.weight || null,
        bmi: basicInfo.bmi || '',
        customData: customFieldsData || {}
      };
    } else {
      // 当 response.data.data 为 null 或服务器没有数据时
      useDefaultUserInfo();
    }
  // } catch (error) {
  //   useDefaultUserInfo();
  // }
}

// 抽取使用默认值的逻辑为单独的函数
const useDefaultUserInfo = () => {
  // 使用父组件传递的默认值
  const defaultUserInfo = props.selections?.userinfo;
  if (defaultUserInfo) {
    userinfo.value = { ...defaultUserInfo };
  } else {
    // 如果连默认值也没有，则清空所有字段
    userinfo.value = {
      username: '',
      sex: '',
      ethnicity: '',
      birthday: '',
      age: '',
      height: null,
      weight: null,
      bmi: '',
      customData: {}
    };
  }
}
// 计算年龄
const computedAge = computed(() => {
  if (!userinfo.value?.birthday) return '--'
  const birthDate = new Date(userinfo.value.birthday)
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  
  return `${age}岁`
})

// 计算 BMI
const computedBMI = computed(() => {
  if (!userinfo.value?.height || !userinfo.value?.weight) return '--'
  const height = userinfo.value.height / 100 // 转换为米
  const weight = userinfo.value.weight
  const bmi = (weight / (height * height)).toFixed(1)
  return bmi
})

// 基础信息配置
const baseInfoItems = computed(() => [
  {
    label: '姓名',
    value: userinfo.value?.username || '--'
  },
  {
    label: '性别',
    value: userinfo.value?.sex || '--'
  },
  {
    label: '出生日期',
    value: userinfo.value?.birthday || '--'
  },
  {
    label: '年龄',
    value: computedAge.value
  },
  {
    label: '民族',
    value: userinfo.value?.ethnicity || '--'
  },
  {
    label: '身高',
    value: userinfo.value?.height ? `${userinfo.value.height} cm` : '--'
  },
  {
    label: '体重',
    value: userinfo.value?.weight ? `${userinfo.value.weight} kg` : '--'
  },
  {
    label: 'BMI',
    value: computedBMI.value
  }
])

// 自定义字段处理
const customFields = computed(() => {
  const customData = userinfo.value?.customData || {}
  
  // 直接返回处理后的对象,保持原有的数据结构
  return Object.entries(customData).reduce((acc, [key, fieldData]) => {
    // 如果数据格式不正确,返回默认值
    if (!fieldData || typeof fieldData !== 'object') {
      acc[key] = {
        value: '--',
        unit: ''
      }
      return acc
    }

    // 保持原有的值和单位
    acc[key] = {
      value: fieldData.value || '--',
      unit: fieldData.unit || ''
    }
    return acc
  }, {})
})
import { useRoute } from 'vue-router'

const route = useRoute()

// 监听路由参数变化
watch(
  () => route.query,
  (query) => {
    if (query.print === 'true') {
      // 给一个短暂的延时,确保内容加载完成
      setTimeout(() => {
        window.print()
      },2000)
    }
  },
  { immediate: true }
)
  </script>
  
<style scoped>
/* 添加新的样式 */
.report-container {
  @apply w-full;
}

.report-page {
  width: 210mm;  /* A4 宽度 */
  height: 285mm;  /* A4 高度 */
  margin: 0 auto;
  padding: 20mm 15mm;  /* 上下左右留白 */
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.page-header {
  @apply bg-white mb-6;
  position: relative;
  margin: -20mm -15mm 6mm -15mm; /* 抵消父容器的 padding */
  padding: 20mm 15mm 0 15mm;
}

.page-content {
  height: calc(297mm - 40mm - 20mm);  /* 总高度减去页眉页脚和padding */
  overflow: hidden;
}

.content-section {
  @apply bg-white rounded-lg shadow-sm p-3 border border-gray-100;
}

.page-footer {
    text-align: center;
  left: 0;
  right: 0;
  bottom: 15mm;
  padding: 0 15mm;
}

/* 打印样式优化 */
@media print {
  .report-container {
    margin: 0;
    padding: 0;
  }

  .report-page {
    margin: 0;
    padding: 20mm 15mm;
    box-shadow: none;
    break-after: page;
  }

  /* 确保每个部分在打印时保持在同一页 */
  .content-section {
    break-inside: avoid;
  }
}
</style>