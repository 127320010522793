import {getColor, ledColor} from '@/plugins/utils/utils';

import {locales}from '@/plugins/utils/locales';
import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

export function setAHIChartOption(reports, ahi_chart) {
    if (!ahi_chart) {
        return;
    }

    const dataAHI = [];
    const dataXAxis = [];

    // 从日期序列转换到图表数据
    let weekmode = false;
    if (Object.keys(reports).length > 0) {
        const total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total <= 60) {
            for (const key in chart_dates) {
                const [month, day] = key.split('/');
                const abbrKey = month + '-' + day;
                // 添加检查
                const ahi = reports[key] && reports[key].ahi != null
                    ? reports[key].ahi.toFixed(1)
                    : null;
                if (ahi !== null) {
                    dataAHI.push({name: abbrKey, value: ahi});
                    dataXAxis.push(abbrKey);
                }
            }
        } else {
            weekmode = true;
            let [ahi_avg, count] = [0, 0];
            let valid = false;
            let weekend = '';
            for (let key in chart_dates) {
                const [year, month, day] = key.split('/');
                const d = new Date();
                d.setFullYear(year);
                d.setMonth(month - 1);
                d.setDate(day);
                d.setHours(0, 0, 0, 0);
                const weekday = d.getDay();

                // 添加检查
                const ahi = reports[key] && reports[key].ahi != null
                    ? reports[key].ahi.toFixed(1)
                    : null;

                if (ahi != null && valid) {
                    ahi_avg += parseFloat(ahi);
                    count++;
                }

                if (weekday === 6) {
                    weekend = month + '-' + day;
                }

                if (weekday === 0) {
                    if (valid && count > 0) {
                        ahi_avg = (ahi_avg / count).toFixed(1);
                        key = month + '-' + day + L.to + weekend;
                        dataAHI.push({name: key, value: ahi_avg});
                        dataXAxis.push(key);
                    }
                    [ahi_avg, count] = [0, 0];
                    weekend = '';
                    if (!valid) {
                        valid = true;
                    }
                }
            }
        }
    }
    dataAHI.reverse();
    dataXAxis.reverse();

    const option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        /* title: {
                text: 'AHI指数',
                padding: [15, 0, 0, 32],
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: 14
                }
            }, */
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text')
            },
            axisPointer: {
                type: 'line',
                label: {
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => value ? (Math.round(value * 10) / 10).toFixed(1) : '-'
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: {alignWithLabel: true},
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : getInterval,
                formatter: (value) => {
                    if (weekmode) {
                        const [weekstart, weekend] = value.split(L.to);
                        const [startMonth, startDay] = weekstart.split('-');
                        const [endMonth] = weekend.split('-');
                        if (startMonth != endMonth || startDay == 1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else {
                            return ''
                        }
                    } else {
                        return value;
                    }
                }
            },
            data: dataXAxis
        }],
        yAxis: {
            type: 'value',
            min: 0,
            minInterval: 5,
            axisLabel: {
                show: true
            }
        },
        visualMap: {
            show: false,
            dimension: 1,
            pieces: [
                {lt: 5, color: ledColor[0]},
                {gte: 5, lt: 15, color: ledColor[1]},
                {gte: 15, lt: 30, color: ledColor[2]},
                {gte: 30, color: ledColor[3]}
            ]
        },
        series: [{
            name: 'AHI',
            type: 'line',
            itemStyle: {color: '#df6047'},
            // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataAHI); },
            symbol: 'circle',
            symbolSize: 5,
            // showAllSymbol: 'true',
            smooth: true,
            data: dataAHI,
            markArea: {
                silent: true,
                label: {
                    position: 'insideTopLeft',
                    color: getColor('title'),
                    fontSize: 8,
                    distance: 2
                },
                data: [
                    [{name: L.normal, yAxis: 0, itemStyle: {color: ledColor[0], opacity: 0.2}}, {yAxis: 5}],
                    [{name: L.osa_mild, yAxis: 5.1, itemStyle: {color: ledColor[1], opacity: 0.2}}, {yAxis: 15}],
                    [{name: L.osa_moderate, yAxis: 15.1, itemStyle: {color: ledColor[2], opacity: 0.2}}, {yAxis: 30}],
                    [{name: L.osa_severe, yAxis: 30.1, itemStyle: {color: ledColor[3], opacity: 0.2}}, {yAxis: 200}]
                ]
            }
        }]
    };
    ahi_chart.setOption(option);
    // console.log("AHI chart optionized with: " + reports.length + " reports");
}

export function setAHI2345ChartOption(reports, ahi2345_chart) {
    if (!ahi2345_chart) {
        return;
    }

    const dataAHI2 = [];
    const dataAHI345 = [];
    const dataXAxis = [];

    // 从日期序列转换到图表数据
    let weekmode = false;
    if (reports.length > 0) {
        const total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total <= 60) {
            for (const key in chart_dates) {
                const [month, day] = key.split('/');
                const abbrKey = month + '-' + day;
                const ahi2 = (Math.round(reports[key].ahi_2 * 10) / 10).toFixed(1);
                const ahi345 = (Math.round(reports[key].ahi_345 * 10) / 10).toFixed(1);
                dataAHI2.push({name: abbrKey, value: ahi2});
                dataAHI345.push({name: abbrKey, value: ahi345});
                dataXAxis.push(abbrKey);
            }
        } else {
            weekmode = true;
            let [ahi2_avg, ahi345_avg, count] = [0, 0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            for (let key in chart_dates) {
                const [year, month, day] = key.split('/');
                const d = new Date();
                d.setFullYear(year);
                d.setMonth(month - 1);
                d.setDate(day);
                d.setHours(0, 0, 0, 0);
                const weekday = d.getDay();

                const ahi2 = (Math.round(reports[key].ahi_2 * 10) / 10).toFixed(1);
                const ahi345 = (Math.round(reports[key].ahi_345 * 10) / 10).toFixed(1);
                if (ahi2 != null && ahi345 != null && valid) {
                    ahi2_avg += parseFloat(ahi2);
                    ahi345_avg += parseFloat(ahi345);
                    count++;
                }
                if (weekday === 6) { // 每周最后一天
                    weekend = month + '-' + day;
                }
                if (weekday === 0) { // 每周第一天
                    if (valid) {
                        ahi2_avg = count ? (ahi2_avg / count).toFixed(1) : null;
                        ahi345_avg = count ? (ahi345_avg / count).toFixed(1) : null;

                        key = month + '-' + day + L.to + weekend;
                        dataAHI2.push({name: key, value: ahi2_avg});
                        dataAHI345.push({name: key, value: ahi345_avg});
                        dataXAxis.push(key);
                        [ahi2_avg, ahi345_avg, count] = [0, 0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    dataAHI2.reverse();
    dataAHI345.reverse();
    dataXAxis.reverse();

    const option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        legend: {
            orient: 'horizontal',
            bottom: 1,
            selectedMode: false,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 20,
            itemGap: 6,
            textStyle: {
                fontSize: 12,
                padding: [0, 0, 0, -6]
            }
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text')
            },
            axisPointer: {
                type: 'line',
                label: {
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => value ? (Math.round(value * 10) / 10).toFixed(1) : '-'
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: weekmode ? '18%' : '12%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: {alignWithLabel: true},
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : getInterval,
                formatter: (value) => {
                    if (weekmode) {
                        const [weekstart, weekend] = value.split(L.to);
                        const [startMonth, startDay] = weekstart.split('-');
                        const [endMonth] = weekend.split('-');
                        if (startMonth != endMonth || startDay == 1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else {
                            return ''
                        }
                    } else {
                        return value;
                    }
                }
            },
            data: dataXAxis
        }],
        yAxis: {
            type: 'value',
            min: 0,
            // minInterval: 5,
            axisLabel: {
                show: true
            }
        },
        series: [{
            name: L.nrem_ahi,
            type: 'line',
            itemStyle: {color: '#CCC'},
            // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataAHI345); },
            symbol: 'circle',
            symbolSize: 5,
            // showAllSymbol: 'true',
            smooth: true,
            data: dataAHI345
        }, {
            name: L.rem_ahi,
            type: 'line',
            itemStyle: {color: '#73c0de'},
            // lineStyle: { type: 'dotted' },
            // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataAHI2); },
            symbol: 'circle',
            symbolSize: 5,
            // showAllSymbol: 'true',
            smooth: true,
            data: dataAHI2
        }]
    };
    ahi2345_chart.setOption(option);
    // console.log("AHI chart optionized with: " + reports.length + " reports");
}
