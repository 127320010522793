import { getColor, formatDateString } from '@/plugins/utils/utils';
export function setArtChartOption (artifact_chart, artifact_data) {
  if (!artifact_chart || !artifact_data) { // 尚未初始化
    return;
  }

  const data = [];
  const dataLeave = [];
  const legendColors = ['#f2bd7d', '#f0a055', '#ee7624', '#8E377A'];
  if (artifact_data.segments) {
    const offset = artifact_data.start;
    const end = artifact_data.end;
    let lastend = 0;
    for (let i = 0; i < artifact_data.segments.length; i++) {
      let type = 0;
      if (artifact_data.segments[i].value > 1 && artifact_data.segments[i].value <= 3) { type = 1; } else if (artifact_data.segments[i].value > 3 && artifact_data.segments[i].value <= 8) { type = 2; } else if (artifact_data.segments[i].value > 8) { type = 3; }
      data.push([offset + artifact_data.segments[i].start, offset + artifact_data.segments[i].end, type]);
      lastend = offset + artifact_data.segments[i].end;
    }
    if (offset + artifact_data.segments[artifact_data.segments.length - 1].end < end) {
      data.push([lastend, end, 0]);
    }
  }
  let hasLeaveData = false;
  if (artifact_data.leavebed) {
    const offset = artifact_data.start;
    for (let i = 0; i < artifact_data.leavebed.length; i++) {
      if (offset + artifact_data.leavebed[i].e <= artifact_data.end) {
        hasLeaveData = true;
        dataLeave.push([offset + artifact_data.leavebed[i].s, offset + artifact_data.leavebed[i].e, 4]);
      }
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: '体动',
      padding: [0, 0, 0, 36],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      left: '0',
      right: '8%',
      top: hasLeaveData ? 20 : 14,
      bottom: 14,
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      offset: -5,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      min: -1,
      max: 1.2,
      show: false
    },
    tooltip: {
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      borderColor: 'transparent',
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        const types = ['很少', '偶尔', '频繁', '离床'];
        return formatDateString(params.value[0] * 1000, 'HHmm') +
                    ' - ' + formatDateString(params.value[1] * 1000, 'HHmm') +
                    ' ' + types[params.value[2] - 1];
      }
    },
    legend: {
      data: [{
        name: '很少',
        itemStyle: { color: legendColors[0] }
      }, {
        name: '偶尔',
        itemStyle: { color: legendColors[1] }
      }, {
        name: '频繁',
        itemStyle: { color: legendColors[2] }
      }, hasLeaveData
        ? {
            name: '离床',
            icon: 'roundRect',
            itemStyle: { color: legendColors[3] }
          }
        : {}],
      orient: 'horizontal',
      bottom: 0,
      selectedMode: false,
      icon: 'circle',
      itemHeight: 12,
      itemWidth: 14
    },
    series: [
      {
        type: 'custom',
        name: '很少',
        renderItem: function (params, api) {
          const start = api.coord([api.value(0), 0]);
          const end = api.coord([api.value(1), api.value(2)]);
          const hoffset = [0, 0.6, 0.7, 0.8, 0.8];
          const height = api.size([0, 1])[1] * hoffset[api.value(2)] * api.value(2);
          const gap = 1;
          return {
            type: 'rect',
            shape: {
              x: start[0] + gap / 2,
              y: start[1] - height / 2,
              width: end[0] - start[0] - gap,
              height,
              r: 4
            },
            style: {
              ...api.style(),
              fill: legendColors[api.value(2) - 1]
            },
            emphasis: {
              style: {
                stroke: legendColors[api.value(2) - 1],
                lineWidth: 3
              }
            }
          }
        },
        encode: {
          x: [0, 1],
          y: 2
        },
        data
      }, {
        type: 'custom',
        name: '偶尔'
      }, {
        type: 'custom',
        name: '频繁'
      }, {
        type: 'custom',
        name: '离床',
        renderItem: function (params, api) {
          const start = api.coord([api.value(0), 0]);
          const end = api.coord([api.value(1), 0]);
          const height = api.size([0, 1])[1] * 3 * 0.8;
          const width = end[0] - start[0];

          const mWidth = 7;
          const mHeight = 6;
          return {
            type: 'group',
            children: [{
              type: 'rect',
              shape: {
                ...api.style(),
                x: start[0],
                y: start[1] - height / 2,
                width,
                height,
                r: 4
              },
              style: {
                ...api.style(),
                fill: legendColors[3],
                opacity: 0.8
              },
              emphasis: {
                style: {
                  stroke: legendColors[3],
                  lineWidth: 3
                }
              },
              z2: 10
            }, width < 5
              ? {
                  type: 'polygon',
                  shape: {
                    points: [[start[0] + width / 2 - mWidth / 2, start[1] - height / 2 - 0.7 * mHeight], [start[0] + width / 2 + mWidth / 2, start[1] - height / 2 - 0.7 * mHeight], [start[0] + width / 2, start[1] - height / 2 + 0.3 * mHeight]]
                  },
                  style: {
                    ...api.style(),
                    fill: legendColors[3],
                    opacity: 0.8
                  },
                  emphasis: {
                    style: {
                      stroke: legendColors[3],
                      lineWidth: 3
                    }
                  },
                  z2: 12
                }
              : { type: 'polygon' }]
          }
        },
        encode: {
          x: [0, 1],
          y: 2
        },
        data: dataLeave
      }
    ]
  };

  artifact_chart.setOption(option);
}
