<template>
  <div class="mobile-login-container">
    <!-- 添加背景图容器 -->
    <div class="background-container">
      <img src="@/assets/index.png" alt="背景图" class="background-image">
      <!-- 可以添加一个渐变遮罩 -->
<!--      <div class="gradient-overlay"></div>-->
    </div>

    <div class="mobile-content">
      <div class="mobile-header">
        <h1 class="welcome-text">欢迎使用</h1>
        <h2 class="product-name">五季医学睡眠监测工作站</h2>
      </div>

      <div class="mobile-form">
        <h2 class="login-title">登录账号</h2>
        <!-- 手机验证码注册表单 -->
        <form @submit.prevent="handleVerifyCodeRegister">
          <div class="form-item">
            <input
                type="tel"
                v-model="verifyForm.phone"
                placeholder="请输入手机号"
                class="input-field"
                @blur="validatePhone"
            >
            <span class="error-message" v-if="phoneError">{{ phoneError }}</span>
          </div>

          <div class="form-item verify-code-item">
            <div class="verify-code-wrapper">
              <input
                  type="text"
                  v-model="verifyForm.code"
                  placeholder="请输入验证码"
                  class="input-field verify-code-input"
                  maxlength="6"
              >
              <button
                  type="button"
                  class="verify-code-btn"
                  :disabled="countdown > 0 || !verifyForm.phone || !!phoneError"
                  @click="sendVerifyCode"
              >
                {{ countdown > 0 ? `${countdown}s后重新发送` : '发送验证码' }}
              </button>
            </div>
            <span class="error-message" v-if="codeError">{{ codeError }}</span>
          </div>

          <button type="submit" class="login-button" :disabled="!isVerifyFormValid">登录</button>
        </form>
        <p style="color: red">{{ message }}</p>
        <div class="divider">
        </div>

        <div class="alternative-login">
          <button class="alt-login-btn" @click="login_btn">使用密码登录</button>
          <button class="alt-login-btn" @click="register_btn">注册账号</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, computed, onUnmounted} from 'vue';
import {useRouter} from 'vue-router';
import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js";
import {store} from "@/plugins/store.js";

const router = useRouter();
const showModal = ref(false);
const modalMessage = ref('');
const modalSubMessage = ref('');
const modalSub1Message = ref('');
const message = ref('')
const register_btn = async () => {
  await router.push('/register'); //

}
const login_btn = async () => {
  await router.push('/'); //

}
// 验证码表单数据
const verifyForm = ref({
  phone: '',
  code: ''
});

// 错误提示
const phoneError = ref('');
const codeError = ref('');

// 倒计时控制
const countdown = ref(0);
let timer = null;

// 手机号验证
const phoneRegex = /^1[3-9]\d{9}$/;
const validatePhone = () => {
  if (!verifyForm.value.phone) {
    phoneError.value = '请输入手机号';
    return false;
  }

  if (!phoneRegex.test(verifyForm.value.phone)) {
    phoneError.value = '请输入正确的手机号';
    return false;
  }

  phoneError.value = '';
  return true;
};

// 发送验证码
const sendVerifyCode = async () => {
  if (!validatePhone()) return;

  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/send-sms', {
      phone: verifyForm.value.phone
    });

    if (response.status === 200) {
      // 开始倒计时
      countdown.value = 60;
      timer = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  } catch (error) {
    console.error('发送验证码失败:', error);
  }
};

// 验证码注册表单验证
const isVerifyFormValid = computed(() => {
  return verifyForm.value.phone &&
      verifyForm.value.code &&
      !phoneError.value &&
      !codeError.value;
});

// 验证码注册处理
const handleVerifyCodeRegister = async () => {
  if (!validatePhone()) return;

  if (!verifyForm.value.code) {
    codeError.value = '请输入验证码';
    return;
  }

  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/login/', {
      username: verifyForm.value.phone,
      code: verifyForm.value.code,
      password: verifyForm.value.code
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    console.log(response)
    if (response.status === 200) {
      const token = response.data.data.token;  // 提取令牌
      // 将 token 存入 localStorage
      localStorage.setItem('authToken', token);
      message.value = '登录成功！正在加载用户信息...';
      store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
      router.push('/org-dashboard'); //
    }
    if (response.status === 401) {
      console.log(response.data)
      message.value = '登录失败;'

    }
    // 如果选择了“记住密码”，则保存用户名和密码到 localStorage
  } catch (error) {
    if (error.response) {
      // 服务器返回了一个非 2xx 的响应代码
      if (error.response.status === 401) {
        console.log(error.response.data);
        message.value = error.response.data.detail; // 或根据需要自定义信息
      } else {
        // 处理其他错误状态码
        message.value = `登录失败，错误代码: ${error.response.status}`;
      }
    } else if (error.request) {
      // 请求已经被发送，但是没有响应
      message.value = '服务器未响应，请稍后再试。';
      console.error(error.request);
    } else {
      // 在设置请求时触发的错误
      message.value = '登录失败，请检查网络或稍后再试。';
      console.error('Error', error.message);
    }

  }
};

// 组件销毁时清除定时器
onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
  }
});
</script>

<style scoped>
.mobile-login-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* 背景图相关样式 */
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.95) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* 内容区域样式 */
.mobile-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.mobile-header {
  padding: 40px 0;
}

.welcome-text {
  color: #30729F;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 20px;
}

.product-name {
  color: #30729F;
  font-size: 32px;
  font-weight: bold;
    margin-left: 20px;

}

.mobile-form {
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
}

.login-title {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
  font-weight:bold;
}

.form-item {
  margin-bottom: 16px;
}


.input-field {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.forgot-password {
  color: #5B7FF3;
  text-decoration: none;
}

.login-button {
  width: 100%;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.divider {
  position: relative;
  margin: 24px 0;
}


.alternative-login {
  display: flex;
  gap: 16px;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}


/* 输入框获得焦点时的样式 */
.input-field:focus {
  border-color: #5B7FF3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(91, 127, 243, 0.2);
}

/* 按钮悬停效果 */
.login-button:hover {
  background: #4B6FD3;
}

.alt-login-btn:hover {
  background: rgba(91, 127, 243, 0.1);
}

/* 复选框样式优化 */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}
.form-item {
  margin-bottom: 20px;
  position: relative;
}
.verify-code-item {
  margin-bottom: 20px;
}
.verify-code-wrapper {
  display: flex;
  gap: 10px;
}

.verify-code-input {
  flex: 1;
}

.verify-code-btn {
  min-width: 120px;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.verify-code-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.verify-code-btn:hover:not(:disabled) {
  background: #265d82;
}
</style>
