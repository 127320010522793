import './assets/main.css'
import { connectWebSockets } from './plugins/websocket';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './plugins/store'
import ElementPlus from 'element-plus'  
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import { Button, Icon, Popup, Calendar } from 'vant'
import 'element-plus/dist/index.css'
import VueApexCharts from 'vue3-apexcharts'
// 修改 v-calendar 的导入方式
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'

import 'vant/lib/index.css'

import './assets/main.css'

const app = createApp(App)

// 注册 Vant 组件
app.use(Button)
app.use(Icon)
app.use(Popup)
app.use(Calendar)
app.use(VueApexCharts)
// 修改 v-calendar 的注册方式
app.use(VCalendar, {})
connectWebSockets()

app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})

app.mount('#app')
