<template>
  <div class="login-container">
    <!-- 左侧产品信息部分 -->
    <div class="left-side">
      <img src="@/assets/index.png" alt="睡眠监护仪" class="background-image">
      <div class="overlay"></div>
      <div class="title-container">
        <h1 class="welcome-text">欢迎使用</h1>
        <h2 class="product-name">五季医学睡眠监测工作站</h2>
      </div>
    </div>
    <!-- 右侧表单部分 -->
    <!-- 右侧表单部分 -->
    <div class="right-side">
      <div class="login-form-box">
        <h2 class="login-title">登录账号</h2>

        <!-- 手机验证码注册表单 -->
        <form @submit.prevent="handleVerifyCodeRegister">
          <div class="form-item">
            <label>手机号</label>
            <input
                type="tel"
                v-model="verifyForm.phone"
                placeholder="请输入手机号"
                class="input-field"
                @blur="validatePhone"
            >
            <span class="error-message" v-if="phoneError">{{ phoneError }}</span>
          </div>

          <div class="form-item verify-code-item">
            <label>验证码</label>
            <div class="verify-code-wrapper">
              <input
                  type="text"
                  v-model="verifyForm.code"
                  placeholder="请输入验证码"
                  class="input-field verify-code-input"
                  maxlength="6"
              >
              <button
                  type="button"
                  class="verify-code-btn"
                  :disabled="countdown > 0 || !verifyForm.phone || !!phoneError"
                  @click="sendVerifyCode"
              >
                {{ countdown > 0 ? `${countdown}s后重新发送` : '发送验证码' }}
              </button>
            </div>
            <span class="error-message" v-if="codeError">{{ codeError }}</span>
          </div>

          <button type="submit" class="login-button" :disabled="!isVerifyFormValid">登录</button>
        </form>
        <p style="color: red">{{ message }}</p>
        <div class="alternative-login">
          <button class="alt-login-btn" @click="login_btn">使用密码登录</button>

          <button class="alt-login-btn" @click="register_btn">注册账号</button>
        </div>
      </div>
    </div>
    <div class="custom-modal" v-if="showModal">
      <div class="modal-content">
        <div class="modal-icon">✓</div>
        <h3>{{ modalMessage }}</h3>
        <p>{{ modalSub1Message }}</p>

        <p>{{ modalSubMessage }}</p>
      </div>
    </div>

  </div>
</template>

<script setup>
import {ref, computed, onUnmounted} from 'vue';
import {useRouter} from 'vue-router';
import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js";
import {store} from "@/plugins/store.js";

const router = useRouter();
const showModal = ref(false);
const modalMessage = ref('');
const modalSubMessage = ref('');
const modalSub1Message = ref('');
const message = ref('')
const register_btn = async () => {
  await router.push('/register'); //

}
const login_btn = async () => {
  await router.push('/'); //

}
// 验证码表单数据
const verifyForm = ref({
  phone: '',
  code: ''
});

// 错误提示
const phoneError = ref('');
const codeError = ref('');

// 倒计时控制
const countdown = ref(0);
let timer = null;

// 手机号验证
const phoneRegex = /^1[3-9]\d{9}$/;
const validatePhone = () => {
  if (!verifyForm.value.phone) {
    phoneError.value = '请输入手机号';
    return false;
  }

  if (!phoneRegex.test(verifyForm.value.phone)) {
    phoneError.value = '请输入正确的手机号';
    return false;
  }

  phoneError.value = '';
  return true;
};

// 发送验证码
const sendVerifyCode = async () => {
  if (!validatePhone()) return;

  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/send-sms', {
      phone: verifyForm.value.phone
    });

    if (response.status === 200) {
      // 开始倒计时
      countdown.value = 60;
      timer = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  } catch (error) {
    console.error('发送验证码失败:', error);
  }
};

// 验证码注册表单验证
const isVerifyFormValid = computed(() => {
  return verifyForm.value.phone &&
      verifyForm.value.code &&
      !phoneError.value &&
      !codeError.value;
});

// 验证码注册处理
const handleVerifyCodeRegister = async () => {
  if (!validatePhone()) return;

  if (!verifyForm.value.code) {
    codeError.value = '请输入验证码';
    return;
  }

  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/login/', {
      username: verifyForm.value.phone,
      code: verifyForm.value.code,
      password: verifyForm.value.code
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    console.log(response)
    if (response.status === 200) {
      const token = response.data.data.token;  // 提取令牌
      // 将 token 存入 localStorage
      localStorage.setItem('authToken', token);
      message.value = '登录成功！正在加载用户信息...';
      store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
      router.push('/org-dashboard'); //
    }
    if (response.status === 401) {
      console.log(response.data)
      message.value = '登录失败;'

    }
    // 如果选择了“记住密码”，则保存用户名和密码到 localStorage
  } catch (error) {
    if (error.response) {
      // 服务器返回了一个非 2xx 的响应代码
      if (error.response.status === 401) {
        console.log(error.response.data);
        message.value = error.response.data.detail; // 或根据需要自定义信息
      } else {
        // 处理其他错误状态码
        message.value = `登录失败，错误代码: ${error.response.status}`;
      }
    } else if (error.request) {
      // 请求已经被发送，但是没有响应
      message.value = '服务器未响应，请稍后再试。';
      console.error(error.request);
    } else {
      // 在设置请求时触发的错误
      message.value = '登录失败，请检查网络或稍后再试。';
      console.error('Error', error.message);
    }

  }
};

// 组件销毁时清除定时器
onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
  }
});
</script>

<style scoped>
/* 原有样式保持不变，添加以下新样式 */

.verify-code-item {
  margin-bottom: 20px;
}

.verify-code-wrapper {
  display: flex;
  gap: 10px;
}

.verify-code-input {
  flex: 1;
}

.verify-code-btn {
  min-width: 120px;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.verify-code-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.verify-code-btn:hover:not(:disabled) {
  background: #265d82;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin-right: 10%;
}

/* 左侧样式 */
.left-side {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 10%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  z-index: 2;
}

.title-container {
  position: relative;
  padding: 60px 0 0 60px;
  z-index: 3;
}

.welcome-text {
  color: #30729F;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}

.product-name {
  color: #30729F;
  font-size: 32px;
  font-weight: bold;
}

/* 右侧登录表单样式 */
.right-side {
  margin-right: 20%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  height: 100%;
  overflow-y: auto;
}

.login-form-box {
  width: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.login-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 500;
}

.form-item {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

.input-field:focus {
  border-color: #30729F;
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}


.login-button {
  width: 100%;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #30729F;
}


.alternative-login {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.alt-login-btn:hover {
  background: #f0f7ff;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.login-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.form-item {
  margin-bottom: 20px;
  position: relative;
}

.input-field.error {
  border-color: #ff4d4f;
}

.input-field.error:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  transition: color 0.3s;
}

.password-toggle:hover {
  color: #30729F;
}

.eye-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

/* 调整输入框右侧内边距，为图标留出空间 */
.password-input-wrapper .input-field {
  padding-right: 40px;
}

/* 自定义弹窗样式 */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.modal-icon {
  font-size: 48px;
  color: #67C23A;
  margin-bottom: 20px;
}

.modal-content h3 {
  margin: 0 0 10px;
  color: #303133;
}

.modal-content p {
  margin: 0;
  color: #909399;
}

/* 添加淡入淡出动画 */
.custom-modal-enter-active,
.custom-modal-leave-active {
  transition: opacity 0.3s ease;
}

.custom-modal-enter-from,
.custom-modal-leave-to {
  opacity: 0;
}

@media (max-width: 1200px) {
  .right-side {
    width: 450px;
  }
}

@media (max-width: 992px) {
  .right-side {
    width: 400px;
  }

  .login-form-box {
    padding: 30px;
  }
}

/* 其他原有样式保持不变 */
</style>
