<template>
  <component :is="currentComponent"></component>
</template>

<script>
import RegisterPhoneCodeDesktop from "@/components/RegisterPhoneCodeDesktop.vue";
import RegisterPhoneCodeMobile from "@/components/RegisterPhoneCodeMobile.vue";
export default {
  name: 'register',
  components: {
    RegisterPhoneCodeDesktop,
    RegisterPhoneCodeMobile
  },
  data() {
    return {
      isMobile: false
    }
  },
  computed: {
    currentComponent() {
      return this.isMobile ? 'RegisterPhoneCodeMobile' : 'RegisterPhoneCodeDesktop'
    }
  },
  created() {
    // 初始检测
    this.checkDevice()
    // 监听窗口大小变化
    window.addEventListener('resize', this.checkDevice)
  },
  destroyed() {
    // 移除监听器
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      // 可以根据屏幕宽度判断
      this.isMobile = window.innerWidth <= 768

      // 或者使用 UserAgent 判断移动设备
      // this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
}
</script>
