import {createStore} from 'vuex';
import {createAxiosInstance} from './axios'; // 确保路径正确

export function loadLocalStorage(d) {
    let res = localStorage.getItem(d) || '{}';
    return JSON.parse(res);
}

export function saveLocalStorage(d, data) {
    localStorage.setItem(d, JSON.stringify(data));
}

export const store = createStore({
    state: {
        user: "", // 用户信息
        isUpdating: false, // 新增：控制批量更新的加载状态
        filter: "",//首页跳转筛选条件
        endpoint: "",
        systemState: {}, // 系统状态信息
        net_error: "",
        net_err_time: 0,
        jumpData: {}
    },
    mutations: {
        setEndpoint(state, endpoint) {
            state.endpoint = endpoint;
            console.log(endpoint);
        },
        clearEndpoint(state) {
            state.endpoint = null;
            console.log('logout');
        },
        setUser(state, user) {
            state.user = user;
        },
        clearUser(state) {
            state.user = null;
            localStorage.removeItem('authToken');
        },
        setSystemState(state, systemState) {
            state.systemState = systemState;
        },
        setNetError(state, net_error) {
            state.net_error = net_error;
            state.net_err_time = new Date().getTime();
        },


        // 新增mutations来更新isUpdating状态
        startUpdating(state) {
            state.isUpdating = true;
        },
        stopUpdating(state) {
            state.isUpdating = false;
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setJumpData(state, data) {
            state.jumpData = data;

        }
    },
    actions: {
        async fetchUser({commit}) {
            try {
                const axiosInstance = createAxiosInstance("数据服务器");
                const response = await axiosInstance.get("/currentuser/"); // 使用新的 axios 实例请求数据
                if (response && response.status === 200 && response.data) {
                    commit('setUser', response.data.data);
                    if (response.sys_info) {
                        commit('setSystemState', response.sys_info);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // store.dispatch('logout');
                    commit('clearUser');
                }
                console.error('Error fetching user:', error);
            }
        },
        async logout({commit}) {
            try {
                let axiosInstance = createAxiosInstance("数据服务器");
                axiosInstance.post('/logout/'); // 发送登出请求到后端
                commit('clearUser'); // 清除本地用户信息
                // 重定向到登录页面或进行其他操作
            } catch (error) {
                console.error('Error during logout:', error);
            }
        },
        // 可以在此处添加更多actions
    },
});
