import { getColor, formatDateString } from '@/plugins/utils/utils';

export function setDayRRChartOption (rr_chart, rr_data) {
  if (!rr_chart || !rr_data) {
    return;
  }

  const data = [];
  const dataMask = [];
  if (rr_data && rr_data.segments) {
    const offset = rr_data.start;
    const end = rr_data.end;
    let lastend = 0;
    if (rr_data.segments[0].time > 0) {
      data.push([offset, null]);
    }
    for (let i = 0; i < rr_data.segments.length; i++) {
      // console.log(rr_data.segments[i].time, rr_data.segments[i].v);
      data.push([offset + rr_data.segments[i].time, rr_data.segments[i].v <= 0 ? null : rr_data.segments[i].v]);
      lastend = offset + rr_data.segments[i].time;
    }
    if (lastend < end) {
      data.push([end, null]);
    }

    for (let i = 0; i < rr_data.nointention?.length; i++) {
      const seg = rr_data.nointention[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '活动', xAxis: rr_data.start + seg.s }, { xAxis: rr_data.start + seg.e }]); }
    }
    for (let i = 0; i < rr_data.leavebed?.length; i++) {
      const seg = rr_data.leavebed[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '离床', xAxis: rr_data.start + seg.s }, { xAxis: rr_data.start + seg.e }]); }
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: '整晚睡眠呼吸频率',
      padding: [0, 0, 0, 32],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      left: '2%',
      right: '7%',
      top: '16%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        lineStyle: { type: 'dashed' }
      }
    },
    yAxis: {
      type: 'value',
      interval: 5,
      min: function (value) { return Math.floor(value.min / 10) * 10 },
      max: function (value) { return Math.ceil(value.max / 10) * 10 }
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return params[0].marker +
                    formatDateString(params[0].value[0] * 1000, 'HHmm') +
                    '  ' + (params[0].value[1] ? params[0].value[1] + '次/分' : '无');
      }
    },
    series: [
      {
        type: 'line',
        symbol: 'none',
        data,
        itemStyle: { color: '#e7a370' },
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            position: 'insideStartTop',
            formatter: function () {
              // return '平均' + Math.round(params.value*10)/10;
              return '平均' + Math.round(rr_data.avg * 10) / 10;
            }
          },
          data: [[{
            name: '平均',
            coord: [rr_data.start + (rr_data.intention || 0), rr_data.avg]
          }, {
            coord: [rr_data.end - (rr_data.leave ? (rr_data.end - rr_data.start - rr_data.leave) : 0), rr_data.avg]
          }]],
          lineStyle: { opacity: 0.8 }
        },
        markArea: {
          silent: false,
          label: {
            show: false
          },
          itemStyle: {
            color: '#CCC',
            opacity: 0.4
          },
          emphasis: {
            label: {
              show: true,
              position: 'right',
              color: getColor('text')
            }
          },
          data: dataMask
        },
        encode: {
          x: 0,
          y: 1,
          tooltip: [0, 1]
        }
      }
    ]
  };

  rr_chart.setOption(option);
}
