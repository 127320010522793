<template>
  <router-view></router-view>
</template>

<script setup>
import {useStore} from 'vuex';
import {onMounted, computed, ref} from 'vue';

const store = useStore();


let intervalId = null; // 用于存储定时器ID

const refresh_user = () => {
  store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
}
onMounted(async () => {
  await store.dispatch('fetchUser'); // 使用Vuex action获取用户信息
  intervalId = setInterval(refresh_user, 1000 * 5); // 每5秒刷新一次用户信息

});
</script>
