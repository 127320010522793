<template>
  <div class="mobile-login-container">
    <!-- 添加背景图容器 -->
    <div class="background-container">
      <img src="@/assets/index.png" alt="背景图" class="background-image">
    </div>

    <div class="mobile-content">
      <div class="mobile-header">
        <h1 class="welcome-text">欢迎使用</h1>
        <h2 class="product-name">五季医学睡眠监测工作站</h2>
      </div>

      <div class="mobile-form">
        <h2 class="login-title">注册账号</h2>
        <form @submit.prevent="handleLogin">
          <div class="form-item">
            <input
                type="tel"
                v-model="RegisterForm.username"
                placeholder="请输入手机号"
                class="input-field"
                @blur="validatePhone"
            >
            <span class="error-message" v-if="phoneError">{{ phoneError }}</span>
          </div>
          <div class="form-item">
            <div class="password-input-wrapper">
              <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="RegisterForm.password"
                  placeholder="请输入密码"
                  class="input-field"
              >
              <span
                  class="password-toggle"
                  @click="showPassword = !showPassword"
                  :title="showPassword ? '隐藏密码' : '显示密码'"
              >
                <!-- 使用图标字体或SVG图标 -->
                <i :class="showPassword ? 'icon-eye-open' : 'icon-eye-close'"></i>
                <!-- 如果使用SVG图标 -->
                <svg v-if="showPassword" class="eye-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                      d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                </svg>
                <svg v-else class="eye-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                      d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                </svg>
              </span>
            </div>
          </div>
          <div class="form-item">
            <div class="password-input-wrapper">
              <input
                  :type="showRePassword ? 'text' : 'password'"
                  v-model="repassword"
                  placeholder="请再次输入密码"
                  class="input-field"
                  @blur="validatePassword"
              >
              <span
                  class="password-toggle"
                  @click="showRePassword = !showRePassword"
                  :title="showRePassword ? '隐藏密码' : '显示密码'"
              >
                <svg v-if="showRePassword" class="eye-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                      d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                </svg>
                <svg v-else class="eye-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                      d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                </svg>
              </span>
            </div>
            <span class="error-message" v-if="passwordError">{{ passwordError }}</span>
          </div>
          <button type="submit" class="login-button" :disabled="!isFormValid">注册</button>
        </form>
        <p style="color: red">{{ message }}</p>
        <div class="divider">
        </div>

        <div class="alternative-login">
          <button class="alt-login-btn" @click="register_btn">使用手机验证码注册账号</button>
          <button class="alt-login-btn" @click="login_btn">已有账号，去登录</button>
        </div>
      </div>
    </div>
    <div class="custom-modal" v-if="showModal">
      <div class="modal-content">
        <div class="modal-icon">✓</div>
        <h3>{{ modalMessage }}</h3>
        <p>{{ modalSubMessage }}</p>
      </div>
    </div>

  </div>
</template>
<script setup>
import {ref, onMounted, watch, computed} from "vue";
import md5 from 'md5';
import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js";

const showPassword = ref(false);
const showRePassword = ref(false);
const repassword = ref('');
import {useRouter} from 'vue-router';

const router = useRouter();
const showModal = ref(false);
const modalMessage = ref('');
const modalSubMessage = ref('');
const message = ref('')
const RegisterForm = ref({
  username: '',
  password: '',
});
const passwordError = ref('');
const phoneError = ref('');
const loginError = ref(false);
const register_btn = async () => {
  await router.push('/register2'); //

}
const login_btn = async () => {
  await router.push('/'); //

}
// 手机号验证正则表达式
const phoneRegex = /^1[3-9]\d{9}$/;

// 验证手机号
const validatePhone = () => {
  if (!RegisterForm.value.username) {
    phoneError.value = '请输入手机号';
    return false;
  }

  if (!phoneRegex.test(RegisterForm.value.username)) {
    phoneError.value = '请输入正确的手机号';
    return false;
  }

  phoneError.value = '';
  return true;
};

// 密码验证函数
const validatePassword = () => {
  if (!RegisterForm.value.password || !repassword.value) {
    passwordError.value = '请输入密码';
    return false;
  }

  if (RegisterForm.value.password !== repassword.value) {
    passwordError.value = '两次输入的密码不一致';
    return false;
  }

  // 可以添加密码强度验证
  if (RegisterForm.value.password.length < 6) {
    passwordError.value = '密码长度不能少于6位';
    return false;
  }

  passwordError.value = '';
  return true;
};

// 表单整体验证
const isFormValid = computed(() => {
  return RegisterForm.value.username &&
      RegisterForm.value.password &&
      repassword.value &&
      RegisterForm.value.password === repassword.value &&
      phoneRegex.test(RegisterForm.value.username) &&
      !passwordError.value &&
      !phoneError.value;
});

const handleLogin = async (event) => {
  event.preventDefault();

  // 表单提交前再次验证
  if (!validatePhone() || !validatePassword()) {
    return;
  }

  loginError.value = false;

  try {
    // 创建一个新的对象，避免直接修改 RegisterForm
    const submitData = {
      userName: RegisterForm.value.username,
      passWord: md5(RegisterForm.value.password)
    };

    const axios = createAxiosInstance("数据服务器");
    console.log(submitData)
    const response = await axios.post('/register_qa/', submitData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      // 处理成功响应
      // 显示成功弹窗
      modalMessage.value = '注册成功！';
      modalSubMessage.value = '即将跳转到登录页...';
      showModal.value = true;

      // 2秒后跳转
      setTimeout(() => {
        showModal.value = false;
        router.push('/');
      }, 2000);
    }
  } catch (error) {
    console.log(error)
    if (error.response) {
      // 服务器返回了一个非 2xx 的响应代码
      if (error.response.status === 401) {
        console.log(error.response.data);
        message.value = error.response.data.detail; // 或根据需要自定义信息
      } else {
        // 处理其他错误状态码
        message.value = `注册失败，错误代码: ${error.response.status}`;
      }
    } else if (error.request) {
      // 请求已经被发送，但是没有响应
      message.value = '服务器未响应，请稍后再试。';
      console.error(error.request);
    } else {
      // 在设置请求时触发的错误
      message.value = '注册失败，请检查网络或稍后再试。';
      console.error('Error', error.message);
    }
  }
};

// 监听手机号输入变化，实时验证格式
watch(() => RegisterForm.value.username, (newVal) => {
  if (newVal) {
    validatePhone();
  } else {
    phoneError.value = '';
  }
});

onMounted(() => {
});
</script>

<style scoped>
.mobile-login-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* 背景图相关样式 */
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.95) 50%,
      rgba(255, 255, 255, 1) 100%
  );
}

/* 内容区域样式 */
.mobile-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.mobile-header {
  padding: 40px 0;
}

.welcome-text {
  color: #30729F;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 20px;
}

.product-name {
  color: #30729F;
  font-size: 32px;
  font-weight: bold;
  margin-left: 20px;

}

.mobile-form {
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
}

.login-title {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
  font-weight: bold;
}

.form-item {
  margin-bottom: 16px;
}


.input-field {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.forgot-password {
  color: #5B7FF3;
  text-decoration: none;
}

.login-button {
  width: 100%;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.divider {
  position: relative;
  margin: 24px 0;
}


.alternative-login {
  display: flex;
  gap: 16px;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}


/* 输入框获得焦点时的样式 */
.input-field:focus {
  border-color: #5B7FF3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(91, 127, 243, 0.2);
}

/* 按钮悬停效果 */
.login-button:hover {
  background: #4B6FD3;
}

.alt-login-btn:hover {
  background: rgba(91, 127, 243, 0.1);
}

/* 复选框样式优化 */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.form-item {
  margin-bottom: 20px;
  position: relative;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.alt-login-btn:hover {
  background: #f0f7ff;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.login-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.input-field.error {
  border-color: #ff4d4f;
}

.input-field.error:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  transition: color 0.3s;
}

.password-toggle:hover {
  color: #30729F;
}

.eye-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

/* 调整输入框右侧内边距，为图标留出空间 */
.password-input-wrapper .input-field {
  padding-right: 40px;
}

/* 自定义弹窗样式 */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.modal-icon {
  font-size: 48px;
  color: #67C23A;
  margin-bottom: 20px;
}

.modal-content h3 {
  margin: 0 0 10px;
  color: #303133;
}

.modal-content p {
  margin: 0;
  color: #909399;
}

</style>
